@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include color(#{map_get_strict($settings, "color")});
  }
}

@mixin color($color) {
  --spinner-color: #{$color};
}

@mixin translate-keyframes($name, $value) {
  @keyframes spinner-translate-#{$name} {
    0% {
      transform: translateY(0);
    }

    12% {
      transform: translateY($value);
    }

    40% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(0);
    }
  }
}
