@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  .search-field {
    @include _sizes(map_get_strict($settings, "sizes"));

    @content;
  }

  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  --search-field-background: #{map_get_strict($settings, "background")};
  --search-field-border-color: #{map_get_strict($settings, "border-color")};
  --search-field-border-radius: #{map_get_strict($settings, "border-radius")};
  --search-field-color: #{map_get_strict($settings, "color")};
  --search-field-transition: #{map_get_strict($settings, "transition")};
  --search-field-active-border-color: #{map_get_strict($settings, "active-border-color")};
  --search-field-hover-border-color: #{map_get_strict($settings, "hover-border-color")};
  --search-field-placeholder-color: #{map_get_strict($settings, "placeholder-color")};
  --search-field-search-icon-color: #{map_get_strict($settings, "search-icon-color")};
  --search-field-clear-icon-color: #{map_get_strict($settings, "clear-icon-color")};
  --search-field-clear-icon-hover-color: #{map_get_strict($settings, "clear-icon-hover-color")};
}

@mixin _sizes($sizes-definitions) {
  @each $name, $definitions in $sizes-definitions {
    @if $name == "default" {
      @include _size-vars($definitions);
    } @else {
      &_#{$name} {
        @include _size-vars($definitions);
      }
    }
  }
}

@mixin _size-vars($sizes-definition) {
  --search-field-height: #{map_get_strict($sizes-definition, "height")};
  --search-field-font-size: #{map_get_strict($sizes-definition, "font-size")};
  --search-field-line-height: #{map_get_strict($sizes-definition, "line-height")};
  --search-field-search-icon-padding: #{map_get_strict($sizes-definition, "search-icon-padding")};
  --search-field-clear-icon-padding: #{map_get_strict($sizes-definition, "clear-icon-padding")};
  --search-field-clear-icon-size: #{map_get_strict($sizes-definition, "clear-icon-size")};
}
