@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  .horizontal-tab-navigation {
    @content;
  }

  @include wrap-with-root {
    @include _tab-vars(map_get_strict($settings, "tab"));
    @include _container-vars(map_get_strict($settings, "container"));
    @include _menu-vars(map_get_strict($settings, "menu"));
    @include _indicator-vars(map_get_strict($settings, "indicator"));
  }
}

@mixin _tab-vars($settings) {
  --horizontal-tab-navigation-tab-padding: #{map_get_strict($settings, "padding")};
  --horizontal-tab-navigation-active-color: #{map_get_strict($settings, "active-color")};
  --horizontal-tab-navigation-inactive-color: #{map_get_strict($settings, "inactive-color")};
  --horizontal-tab-navigation-tab-content-max-width: #{map_get_strict($settings, "content-max-width")};
  --horizontal-tab-navigation-tab-bar-height: #{map_get_strict($settings, "bar-height")};
  --horizontal-tab-navigation-tab-bar-hover-color: #{map_get_strict($settings, "bar-hover-color")};
  --horizontal-tab-navigation-tab-active-bar-background: #{map_get_strict($settings, "active-bar-background")};
  --horizontal-tab-navigation-tab-font-size: #{map_get_strict($settings, "font-size")};
}

@mixin _container-vars($settings) {
  --horizontal-tab-navigation-container-box-shadow: #{map_get_strict($settings, "box-shadow")};
  --horizontal-tab-navigation-container-padding-left: #{map_get_strict($settings, "padding-left")};
  --horizontal-tab-navigation-container-padding-right: #{map_get_strict($settings, "padding-right")};
  --horizontal-tab-navigation-container-tablet-padding-left: #{map_get_strict($settings, "tablet-padding-left")};
  --horizontal-tab-navigation-container-tablet-padding-right: #{map_get_strict($settings, "tablet-padding-right")};
}

@mixin _menu-vars($settings) {
  --horizontal-tab-navigation-menu-background: #{map_get_strict($settings, "background")};
  --horizontal-tab-navigation-menu-border-color: #{map_get_strict($settings, "border-color")};
  --horizontal-tab-navigation-menu-border-radius: #{map_get_strict($settings, "border-radius")};
  --horizontal-tab-navigation-menu-box-shadow: #{map_get_strict($settings, "box-shadow")};
  --horizontal-tab-navigation-menu-item-color: #{map_get_strict($settings, "item-color")};
  --horizontal-tab-navigation-menu-item-hover-color: #{map_get_strict($settings, "item-hover-color")};
}

@mixin _indicator-vars($settings) {
  --horizontal-tab-navigation-indicator-border-radius: #{map_get_strict($settings, "border-radius")};
}
