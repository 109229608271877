@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --collapsible-multi-select-value-placeholder: #{map_get_strict($settings, "placeholder")};
  --collapsible-multi-select-value-tag-background: #{map_get_strict($settings, "tag-background")};
  --collapsible-multi-select-value-tag-text-color: #{map_get_strict($settings, "tag-text-color")};
  --collapsible-multi-select-value-hidden-options-counter: #{map_get_strict($settings, "hidden-options-counter")};
}
