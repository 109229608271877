@use "variables" as *;
@use "shared/mixins/break-word" as *;
@use "shared/mixins/media-queries" as *;

@mixin breadcrumbs-with-icon-grid-template($icon-size, $icon-margin) {
  grid-template:
    "icon breadcrumbs" auto /
    auto calc(100% - ($icon-size + $icon-margin));
}

$icon-size-default: 44px;
$icon-size-small: 28px;
$icon-margin-default: 16px;
$icon-margin-small: 8px;

.page-header {
  $block: &;

  padding: 32px;
  position: relative;

  &_bordered {
    border-bottom: 1px solid $lcap-border-default;
  }

  &_small {
    padding: 12px 32px;
  }

  &__content {
    display: grid;
    grid-template:
      "icon main aside" auto /
      auto 1fr auto;

    @include device(tablet) {
      grid-template:
        "icon main" auto
        "aside aside" auto /
        auto 1fr;
    }
  }

  &__breadcrumbs-with-icon {
    display: grid;

    @include breadcrumbs-with-icon-grid-template($icon-size-default, $icon-margin-default);

    #{$block}_small & {
      @include breadcrumbs-with-icon-grid-template($icon-size-small, $icon-margin-small);
    }
  }

  &__main {
    align-self: center;
    grid-area: main;
  }

  &__aside {
    align-self: center;
    grid-area: aside;
    margin-left: 32px;
    text-align: right;

    @include device(tablet) {
      margin-left: 0;
      margin-top: 16px;
      text-align: left;
      width: 100%;
    }
  }

  &__icon-wrapper {
    align-items: center;
    align-self: stretch;
    border-radius: $border-radius-m;
    display: flex;
    grid-area: icon;
    // fix for https://bugs.webkit.org/show_bug.cgi?id=26559 in safari
    // children can't inherit parent's height if min-height is used
    height: 100%;
    justify-content: center;
    margin: 0 $icon-margin-default 0 0;
    min-height: $icon-size-default;
    overflow: hidden;
    width: $icon-size-default;

    #{$block}_small & {
      border-radius: $border-radius-s;
      margin-right: $icon-margin-small;
      min-height: $icon-size-small;
      width: $icon-size-small;
    }
  }

  &__icon {
    border-radius: $border-radius-m;
    display: inline-block;
    height: $icon-size-default;
    overflow: hidden;
    width: $icon-size-default;

    #{$block}_small & {
      border-radius: $border-radius-s;
      height: $icon-size-small;
      width: $icon-size-small;
    }
  }

  &__title {
    color: $lcap-text-primary;
    font-size: $h2-font-size;
    font-weight: 700;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    word-break: break-word;
  }

  &__breadcrumbs {
    display: block;
    grid-area: breadcrumbs;
  }

  &__aside-actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__aside-separator {
    background: $lcap-border-default;
    height: 36px;
    margin: 0 12px;
    width: 1px;

    &:last-child {
      display: none;
    }
  }
}
