/* stylelint-disable color-no-hex, function-disallowed-list */

$lcap-surface-default: #fff;
$lcap-surface-subtle: #f3f5f6;
$lcap-surface-inverse: #133440;
$lcap-surface-alt: #e4e8eb;
$lcap-surface-inverse-opacity-5: rgba($lcap-surface-inverse, 0.05);
$lcap-surface-inverse-opacity-15: rgba($lcap-surface-inverse, 0.15);
$lcap-surface-inverse-opacity-50: rgba($lcap-surface-inverse, 0.5);
$lcap-surface-inverse-opacity-70: rgba($lcap-surface-inverse, 0.7);
$lcap-surface-field: $lcap-surface-default;
$lcap-surface-elevated: $lcap-surface-default;
$lcap-surface-opacity-50: rgba(#fafbfc, 0.5);
$lcap-surface-dark: #04141a;
$lcap-surface-dark-opacity-75: rgba($lcap-surface-dark, 0.75);

// Page background
$lcap-backdrop-default: #fafbfc;
