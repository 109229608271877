@use "text" as *;
@use "shared/lcap/variables/colors" as *;
@use "shared/lcap/variables/borders" as *;
@use "shared/lcap/variables/text" as *;

$lcap-border-inverse: $lcap-text-inverse;

$border-radius-xs: 2px;
$border-radius-s: 4px;
$border-radius-m: 8px;
$border-radius-l: 12px;
$border-radius-xl: 16px;
