@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  --lcap-dashboard-widget-background: #{map_get_strict($settings, "background")};
  --lcap-dashboard-widget-border: #{map_get_strict($settings, "border")};
  --lcap-dashboard-widget-border-radius: #{map_get_strict($settings, "border-radius")};
  --lcap-dashboard-widget-box-shadow: #{map_get_strict($settings, "box-shadow")};
  --lcap-dashboard-widget-error-color: #{map_get_strict($settings, "error-color")};
}
