@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --tooltip-background-color: #{map_get_strict($settings, "background-color")};
  --tooltip-text-color: #{map_get_strict($settings, "text-color")};
  --tooltip-border-radius: #{map_get_strict($settings, "border-radius")};
  --tooltip-border: #{map_get_strict($settings, "border")};
  --tooltip-outline-color: #{map_get_strict($settings, "outline-color")};

  @include _hotkey(map_get_strict($settings, "hotkey"));
}

@mixin _hotkey($settings) {
  --tooltip-hotkey-background-color: #{map_get_strict($settings, "background-color")};
  --tooltip-hotkey-border-color: #{map_get_strict($settings, "border-color")};
  --tooltip-hotkey-border-radius: #{map_get_strict($settings, "border-radius")};
  --tooltip-hotkey-color: #{map_get_strict($settings, "color")};
}
