@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --multi-date-input-active-border: #{map_get_strict($settings, "active-border")};
  --multi-date-input-inner-border: #{map_get_strict($settings, "inner-border")};
  --multi-date-input-active-shadow: #{map_get_strict($settings, "active-shadow")};
  --multi-date-input-border-radius: #{map_get_strict($settings, "input-border-radius")};
  --multi-date-input-background-with-focus: #{map_get_strict($settings, "background-with-focus")};
}
