@use "shared/theming-mixins/spinner";

.spinner {
  $spinner-selector: &;
  $content-height: 12px;
  $content-small-width: 20px;
  $bar-width: 2px;
  $bar-height: 4px;
  $bar-edge-height: 1px;
  $bar-border-radius: 1px;
  $bar-margin-right: 2px;
  $animation: cubic-bezier(0.3, 0.02, 0.2, 1) forwards infinite;

  $large-ratio: 2;

  display: inline-block;
  text-align: left;
  vertical-align: middle;

  &_centered {
    display: flex;
    justify-content: center;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: $content-height;

    &_small {
      width: $content-small-width;
    }

    &_large {
      height: $content-height * $large-ratio;

      #{$spinner-selector}__bar {
        animation-name: spinner-opacity;
        height: $bar-height * $large-ratio;
        margin-right: $bar-margin-right * $large-ratio;
        width: $bar-width * $large-ratio;

        @for $i from 1 through 8 {
          /* stylelint-disable max-nesting-depth */
          &:nth-child(#{$i}) {
            &,
            &::before,
            &::after,
            #{$spinner-selector}__bar-inner {
              animation-delay: #{$i * 0.08}s;
              animation-duration: 1.2s;
            }
          }
        }

        &::before,
        &::after,
        #{$spinner-selector}__bar-inner {
          width: $bar-width * $large-ratio;
        }

        &::before {
          animation-name: spinner-translate-top-large;
          border-radius: $bar-border-radius * $large-ratio $bar-border-radius * $large-ratio 0 0;
          height: $bar-edge-height * $large-ratio + 1;
        }

        &::after {
          animation-name: spinner-translate-bottom-large;
          border-radius: 0 0 $bar-border-radius * $large-ratio $bar-border-radius * $large-ratio;
          height: $bar-edge-height * $large-ratio + 1;
        }

        #{$spinner-selector}__bar-inner {
          height: ($bar-height - 2 * $bar-edge-height) * $large-ratio;
          top: $bar-edge-height * $large-ratio;
        }

        @include spinner.translate-keyframes("top-large", -6px);
        @include spinner.translate-keyframes("bottom-large", 6px);
      }
    }
  }

  &__bar {
    animation: $animation;
    animation-name: spinner-opacity;
    height: $bar-height;
    margin-right: $bar-margin-right;
    opacity: 0.3;
    position: relative;
    width: $bar-width;

    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        &,
        &::before,
        &::after,
        #{$spinner-selector}__bar-inner {
          animation-delay: #{$i * 0.1}s;
          animation-duration: 1s;
        }
      }
    }

    &::before,
    &::after {
      content: "";
    }

    &::before,
    &::after,
    #{$spinner-selector}__bar-inner {
      animation: $animation;
      background-color: var(--spinner-color);
      position: absolute;
      width: $bar-width;
    }

    &::before {
      animation-name: spinner-translate-top;
      border-radius: $bar-border-radius $bar-border-radius 0 0;
      height: $bar-edge-height + 1;
      top: 0;
    }

    &::after {
      animation-name: spinner-translate-bottom;
      border-radius: 0 0 $bar-border-radius $bar-border-radius;
      bottom: 0;
      height: $bar-edge-height + 1;
    }

    #{$spinner-selector}__bar-inner {
      animation-name: spinner-scale;
      height: $bar-height - 2 * $bar-edge-height;
      top: 1px;
    }

    @include spinner.translate-keyframes("top", -3px);
    @include spinner.translate-keyframes("bottom", 3px);
  }

  @keyframes spinner-scale {
    0% {
      transform: scaleY(1);
    }

    12% {
      transform: scaleY(4);
    }

    40% {
      transform: scaleY(1);
    }

    100% {
      transform: scaleY(1);
    }
  }

  @keyframes spinner-opacity {
    0% {
      opacity: 0.3;
    }

    12% {
      opacity: 1;
    }

    40% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.3;
    }
  }
}
