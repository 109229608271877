@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src:
    local("Source Sans Pro Light"),
    local("SourceSansPro-Light"),
    font-url("Source_Sans_Pro/Light.woff2") format("woff2"),
    font-url("Source_Sans_Pro/Light.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src:
    local("Source Sans Pro Regular"),
    local("SourceSansPro-Regular"),
    font-url("Source_Sans_Pro/Regular.woff2") format("woff2"),
    font-url("Source_Sans_Pro/Regular.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src:
    local("Source Sans Pro SemiBold"),
    local("SourceSansPro-SemiBold"),
    font-url("Source_Sans_Pro/SemiBold.woff2") format("woff2"),
    font-url("Source_Sans_Pro/SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src:
    local("Source Sans Pro Bold"),
    local("SourceSansPro-Bold"),
    font-url("Source_Sans_Pro/Bold.woff2") format("woff2"),
    font-url("Source_Sans_Pro/Bold.woff") format("woff");
}

@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 400;
  src:
    local("Source Code Pro"),
    local("SourceCodePro-Regular"),
    font-url("Source_Code_Pro/Regular.woff2") format("woff2"),
    font-url("Source_Code_Pro/Regular.woff") format("woff");
}
