@mixin form-control-main {
  background: var(--form-control-background);
  border: var(--form-control-border);
  border-radius: var(--form-control-border-radius);
  color: var(--form-control-color);
  font-family: inherit;
  font-size: var(--form-control-font-size, 14px);
  font-weight: 400;
  line-height: inherit;
  min-height: var(--form-control-min-height);
  padding: var(--form-control-padding);
  transition:
    box-shadow ease-in-out 250ms,
    border-color ease-in-out 250ms;
  width: 100%;
}

@mixin form-control {
  @include form-control-main;

  &:focus {
    border-color: var(--form-control-focus-border-color);
    box-shadow: var(--form-control-focus-box-shadow);
    outline: none;
  }

  &::placeholder {
    color: var(--form-control-placeholder-color);
  }

  // Removes inner shadow in text fields in Mobile Safari
  &:not([type="checkbox"], [type="radio"]) {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
  }

  &[type="text"]:disabled {
    background: var(--form-control-disabled-background);
    color: var(--form-control-disabled-color);
    cursor: not-allowed;
  }

  &[type="radio"] {
    height: auto;
    margin-right: 10px;
    padding: inherit;
    width: auto;
  }

  @at-root {
    textarea#{&} {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
}
