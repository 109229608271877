@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --switch-inactive-color: #{map_get($settings, "inactive-color")};
  --switch-active-color: #{map_get($settings, "active-color")};
  --switch-color: #{map_get($settings, "color")};
  --switch-font-size: #{map_get($settings, "font-size")};
}
