@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars(map_get_strict($settings, "dropdown-menu"));
    @include confirmation-item-vars(map_get_strict($settings, "confirmation-item"));
    @include trigger-vars(map_get_strict($settings, "trigger"));
    @include item-vars(map_get_strict($settings, "item"));
  }
}

@mixin base-vars($settings) {
  --dropdown-menu-border-radius: #{map_get_strict($settings, "border-radius")};
  --dropdown-menu-box-shadow: #{map_get_strict($settings, "box-shadow")};
  --dropdown-menu-container-background: #{map_get_strict($settings, "container-background")};
  --dropdown-menu-container-border: #{map_get_strict($settings, "container-border")};
  --dropdown-menu-container-border-radius: #{map_get_strict($settings, "container-border-radius")};
  --dropdown-menu-container-border-radius-for-phone: #{map_get_strict($settings, "container-border-radius-for-phone")};
  --dropdown-menu-item-bordered-border: #{map_get_strict($settings, "item-border-bottom")};
  --dropdown-menu-header-border-bottom: #{map_get_strict($settings, "header-border-bottom")};
  --dropdown-menu-header-color: #{map_get_strict($settings, "header-color")};
  --dropdown-menu-cancel-button-border-top: #{map_get_strict($settings, "cancel-button-border-top")};
  --dropdown-menu-cancel-button-color: #{map_get_strict($settings, "cancel-button-color")};
}

@mixin confirmation-item-vars($settings) {
  --dropdown-menu-item-confirmation-item-negative-color: #{map_get_strict($settings, "negative-color")};
  --dropdown-menu-item-confirmation-item-warning-color: #{map_get_strict($settings, "warning-color")};
}

@mixin trigger-vars($settings) {
  --dropdown-menu-trigger-icon-fill: #{map_get_strict($settings, "icon-fill")};
  --dropdown-menu-trigger-icon-hovered-fill: #{map_get_strict($settings, "icon-hovered-fill")};
}

@mixin item-vars($settings) {
  --dropdown-menu-item-border-radius: #{map_get_strict($settings, "border-radius")};
  --dropdown-menu-item-color: #{map_get_strict($settings, "color")};
  --dropdown-menu-item-link-color: #{map_get_strict($settings, "link-color")};
  --dropdown-menu-item-selected-color: #{map_get_strict($settings, "selected-color")};
  --dropdown-menu-item-selected-background: #{map_get_strict($settings, "selected-background")};
  --dropdown-menu-item-selected-icon-color: #{map_get_strict($settings, "selected-icon-color")};
  --dropdown-menu-item-disabled-color: #{map_get_strict($settings, "disabled-color")};
  --dropdown-menu-item-disabled-icon-color: #{map_get_strict($settings, "disabled-icon-color")};
  --dropdown-menu-item-negative-icon-color: #{map_get_strict($settings, "negative-icon-color")};
  --dropdown-menu-item-error-color: #{map_get_strict($settings, "error-color")};
  --dropdown-menu-item-icon-color: #{map_get_strict($settings, "icon-color")};
  --dropdown-menu-item-title-disabled-color: #{map_get_strict($settings, "title-disabled-color")};
  --dropdown-menu-item-description-color: #{map_get_strict($settings, "description-color")};
  --dropdown-menu-item-hotkey-color: #{map_get_strict($settings, "hotkey-color")};
  --dropdown-menu-item-hotkey-disabled-color: #{map_get_strict($settings, "hotkey-disabled-color")};
}
