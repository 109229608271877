@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/background-image-theme" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  .empty {
    @content;
  }

  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --empty-background: #{map_get_strict($settings, "background")};
  --empty-border-color: #{map_get_strict($settings, "border-color")};
  --empty-border-radius: #{map_get_strict($settings, "border-radius")};
  --empty-title-color: #{map_get_strict($settings, "title-color")};
  --empty-text-color: #{map_get_strict($settings, "text-color")};
  --empty-placeholder-background: #{map_get_strict($settings, "placeholder-background")};
}

@mixin use-image($name, $path, $shared: false) {
  .empty__img_#{$name} {
    background-image: get-image-url($shared, "#{$path}.svg");
  }
}

@mixin use-theme-image($name, $path, $has-oem-override: false, $shared: false) {
  .empty__img_#{$name} {
    @include background-image-theme($path, "svg", $has-oem-override, $shared);
  }
}
