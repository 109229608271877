@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _select-vars(map_get_strict($settings, "select"));
    @include _select-dropdown-element-vars(map_get_strict($settings, "select-dropdown-element"));
    @include _select-dropdown-vars(map_get_strict($settings, "select-dropdown"));
    @include _themes(map_get_strict($settings, "themes"));
    @include _select-error-vars(map_get_strict($settings, "error"));
    @include _select-warning-vars(map_get_strict($settings, "warning"));
  }
}

@mixin _select-vars($settings) {
  --select-background: #{map_get_strict($settings, "background")};
  --select-border: #{map_get_strict($settings, "border")};
  --select-border-radius: #{map_get_strict($settings, "border-radius")};
  --select-color: #{map_get_strict($settings, "color")};
  --select-cursor: #{map_get_strict($settings, "cursor")};
  --select-min-height: #{map_get_strict($settings, "min-height")};
  --select-min-width: #{map_get_strict($settings, "min-width")};
  --select-padding: #{map_get_strict($settings, "padding")};
  --select-transition: #{map_get_strict($settings, "transition")};
  --select-focused-border-color: #{map_get_strict($settings, "focused-border-color")};
  --select-focused-box-shadow: #{map_get_strict($settings, "focused-box-shadow")};
  --select-invalid-border-color: #{map_get_strict($settings, "invalid-border-color")};
  --select-disabled-background: #{map_get_strict($settings, "disabled-background")};
  --select-disabled-border-color: #{map_get_strict($settings, "disabled-border-color")};
  --select-disabled-color: #{map_get_strict($settings, "disabled-color")};
  --select-disabled-cursor: #{map_get_strict($settings, "disabled-cursor")};
  --select-opened-border-radius: #{map_get_strict($settings, "opened-border-radius")};
  --select-icon-color: #{map_get_strict($settings, "icon-color")};
  --select-value-display: #{map_get_strict($settings, "value-display")};
  --select-value-placeholder-color: #{map_get_strict($settings, "value-placeholder-color")};
  --select-value-disabled-color: #{map_get_strict($settings, "value-disabled-color")};
  --select-value-disabled-cursor: #{map_get_strict($settings, "value-disabled-cursor")};
  --select-selected-item-background: #{map_get_strict($settings, "selected-item-background")};
  --select-selected-item-color: #{map_get_strict($settings, "selected-item-color")};
  --select-selected-item-transtition: #{map_get_strict($settings, "selected-item-transtition")};
}

// Placed on .select__dropdown element
@mixin _select-dropdown-element-vars($settings) {
  --select-dropdown-element-border-color: #{map_get_strict($settings, "border-color")};
  --select-dropdown-element-border-radius: #{map_get_strict($settings, "border-radius")};
  --select-dropdown-element-border-top-color: #{map_get_strict($settings, "border-top-color")};
  --select-dropdown-element-box-shadow: #{map_get_strict($settings, "box-shadow")};
  --select-dropdown-element-margin: #{map_get_strict($settings, "margin")};
  --select-dropdown-element-flipped-border-radius: #{map_get_strict($settings, "flipped-border-radius")};
}

// Placed on .select-dropdown component
@mixin _select-dropdown-vars($settings) {
  --select-dropdown-background: #{map_get_strict($settings, "background")};
  --select-dropdown-max-height: #{map_get_strict($settings, "max-height")};
  --select-dropdown-items-padding: #{map_get_strict($settings, "items-padding")};
  --select-dropdown-item-border-bottom: #{map_get_strict($settings, "item-border-bottom")};
  --select-dropdown-item-border-radius: #{map_get_strict($settings, "item-border-radius")};
  --select-dropdown-item-color: #{map_get_strict($settings, "item-color")};
  --select-dropdown-item-margin: #{map_get_strict($settings, "item-margin")};
  --select-dropdown-item-padding: #{map_get_strict($settings, "item-padding")};
  --select-dropdown-item-focused-background: #{map_get_strict($settings, "item-focused-background")};
  --select-dropdown-item-disabled-background: #{map_get_strict($settings, "item-disabled-background")};
  --select-dropdown-item-disabled-color: #{map_get_strict($settings, "item-disabled-color")};
  --select-dropdown-item-selected-color: #{map_get_strict($settings, "item-selected-color")};
  --select-dropdown-item-recommended-marker-background: #{map_get_strict(
      $settings,
      "item-recommended-marker-background"
    )};
  --select-dropdown-item-label-color: #{map_get_strict($settings, "item-label-color")};
  --select-dropdown-item-icon-color: #{map_get_strict($settings, "item-icon-color")};
  --select-dropdown-item-checkbox-margin: #{map_get_strict($settings, "item-checkbox-margin")};
  --select-dropdown-items-group-padding: #{map_get_strict($settings, "items-group-padding")};
  --select-dropdown-items-group-margin: #{map_get_strict($settings, "items-group-margin")};
  --select-dropdown-items-group-background: #{map_get_strict($settings, "items-group-background")};
  --select-dropdown-items-group-border-top: #{map_get_strict($settings, "items-group-border-top")};
  --select-dropdown-items-group-border-bottom: #{map_get_strict($settings, "items-group-border-bottom")};
  --select-dropdown-items-group-color: #{map_get_strict($settings, "items-group-color")};
  --select-dropdown-item-hint-color: #{map_get_strict($settings, "item-hint-color")};
  --select-dropdown-item-highlight-color: #{map_get_strict($settings, "item-highlight-color")};
  --select-dropdown-item-highlight-font-weight: #{map_get_strict($settings, "item-highlight-font-weight")};
  --select-dropdown-item-invalid-mark-color: #{map_get_strict($settings, "invalid-mark-color")};
  --select-dropdown-search-field-background: #{map_get_strict($settings, "search-field-background")};
  --select-dropdown-search-field-border-bottom: #{map_get_strict($settings, "search-field-border-bottom")};
}

@mixin _themes($settings) {
  @include _select-fluid-theme-vars(map_get_strict($settings, "fluid"));
  @include _select-fluid-borderless-theme-vars(map_get_strict($settings, "fluid-borderless"));
  @include _select-grayscale-theme-vars(map_get_strict($settings, "grayscale"));
  @include _select-insights-theme-vars(map_get_strict($settings, "insights"));
}

@mixin _select-fluid-theme-vars($settings) {
  --select-fluid-border-radius: #{map_get_strict($settings, "border-radius")};
  --select-fluid-min-height: #{map_get_strict($settings, "min-height")};
  --select-fluid-empty-border-color: #{map_get_strict($settings, "empty-border-color")};
  --select-fluid-empty-color: #{map_get_strict($settings, "empty-color")};
  --select-fluid-empty-hover-background: #{map_get_strict($settings, "empty-hover-background")};
  --select-fluid-empty-hover-border-color: #{map_get_strict($settings, "empty-hover-border-color")};
  --select-fluid-empty-hover-color: #{map_get_strict($settings, "empty-hover-color")};
  --select-fluid-empty-focused-background: #{map_get_strict($settings, "empty-focused-background")};
  --select-fluid-empty-focused-border-color: #{map_get_strict($settings, "empty-focused-border-color")};
  --select-fluid-empty-focused-color: #{map_get_strict($settings, "empty-focused-color")};
  --select-fluid-empty-focused-hover-border-color: #{map_get_strict($settings, "empty-focused-hover-border-color")};
  --select-fluid-accent-border-color: #{map_get_strict($settings, "accent-border-color")};
  --select-fluid-accent-color: #{map_get_strict($settings, "accent-color")};
  --select-fluid-hover-background: #{map_get_strict($settings, "hover-background")};
  --select-fluid-hover-border-color: #{map_get_strict($settings, "hover-border-color")};
  --select-fluid-focused-background: #{map_get_strict($settings, "focused-background")};
  --select-fluid-focused-border-color: #{map_get_strict($settings, "focused-border-color")};
  --select-fluid-focused-hover-border-color: #{map_get_strict($settings, "focused-hover-border-color")};
  --select-fluid-invalid-border-color: #{map_get_strict($settings, "invalid-border-color")};
  --select-fluid-invalid-hover-background: #{map_get_strict($settings, "invalid-hover-background")};
  --select-fluid-invalid-hover-border-color: #{map_get_strict($settings, "invalid-hover-border-color")};
  --select-fluid-invalid-focused-background: #{map_get_strict($settings, "invalid-focused-background")};
  --select-fluid-invalid-focused-border-color: #{map_get_strict($settings, "invalid-focused-border-color")};
  --select-fluid-invalid-focused-hover-border-color: #{map_get_strict($settings, "invalid-focused-hover-border-color")};
  --select-fluid-disabled-arrow-color: #{map_get_strict($settings, "disabled-arrow-color")};
  --select-fluid-selected-item-background: #{map_get_strict($settings, "selected-item-background")};
  --select-fluid-selected-item-color: #{map_get_strict($settings, "selected-item-color")};
  --select-fluid-selected-item-hover-background: #{map_get_strict($settings, "selected-item-hover-background")};
  --select-fluid-selected-item-hover-color: #{map_get_strict($settings, "selected-item-hover-color")};
  --select-fluid-dropdown-element-border: #{map_get_strict($settings, "dropdown-element-border")};
  --select-fluid-dropdown-element-border-radius: #{map_get_strict($settings, "dropdown-element-border-radius")};
  --select-fluid-dropdown-element-box-shadow: #{map_get_strict($settings, "dropdown-element-box-shadow")};
  --select-fluid-dropdown-element-margin: #{map_get_strict($settings, "dropdown-element-margin")};
  --select-fluid-dropdown-scrollbar-color: #{map_get_strict($settings, "dropdown-scrollbar-color")};
  --select-fluid-dropdown-scrollbar-width: #{map_get_strict($settings, "dropdown-scrollbar-width")};
  --select-fluid-dropdown-scrollbar-hover-color: #{map_get_strict($settings, "dropdown-scrollbar-hover-color")};
  --select-fluid-dropdown-scrollbar-height: #{map_get_strict($settings, "dropdown-scrollbar-height")};
  --select-fluid-dropdown-border-radius: #{map_get_strict($settings, "dropdown-border-radius")};
  --select-fluid-dropdown-item-border-radius: #{map_get_strict($settings, "dropdown-item-border-radius")};
  --select-fluid-dropdown-item-selected-color: #{map_get_strict($settings, "dropdown-item-selected-color")};
  --select-fluid-dropdown-item-icon-border-radius: #{map_get_strict($settings, "dropdown-item-icon-border-radius")};
  --select-fluid-dropdown-items-group-border-top: #{map_get_strict($settings, "dropdown-items-group-border-top")};
  --select-fluid-dropdown-items-search-group-border-top: #{map_get_strict(
      $settings,
      "dropdown-items-search-group-border-top"
    )};
  --select-fluid-dropdown-items-padding: #{map_get_strict($settings, "dropdown-items-padding")};
}

@mixin _select-fluid-borderless-theme-vars($settings) {
  --select-fluid-borderless-border-radius: #{map_get_strict($settings, "border-radius")};
  --select-fluid-borderless-min-height: #{map_get_strict($settings, "min-height")};
  --select-fluid-borderless-color: #{map_get_strict($settings, "color")};
  --select-fluid-borderless-hover-background: #{map_get_strict($settings, "hover-background")};
  --select-fluid-borderless-hover-border-color: #{map_get_strict($settings, "hover-border-color")};
  --select-fluid-borderless-hover-color: #{map_get_strict($settings, "hover-color")};
  --select-fluid-borderless-focused-background: #{map_get_strict($settings, "focused-background")};
  --select-fluid-borderless-focused-border-color: #{map_get_strict($settings, "focused-border-color")};
  --select-fluid-borderless-focused-color: #{map_get_strict($settings, "borderless-focused-color")};
  --select-fluid-borderless-focused-hover-border-color: #{map_get_strict($settings, "focused-hover-border-color")};
  --select-fluid-borderless-value-placeholder-color: #{map_get_strict($settings, "value-placeholder-color")};
}

@mixin _select-grayscale-theme-vars($settings) {
  --select-grayscale-background: #{map_get_strict($settings, "background")};
  --select-grayscale-focused-border-color: #{map_get_strict($settings, "focused-border-color")};
  --select-grayscale-disabled-background: #{map_get_strict($settings, "disabled-background")};
  --select-grayscale-disabled-border-color: #{map_get_strict($settings, "disabled-border-color")};
  --select-grayscale-disabled-color: #{map_get_strict($settings, "disabled-color")};
  --select-grayscale-dropdown-border-color: #{map_get_strict($settings, "dropdown-border-color")};
}

@mixin _select-insights-theme-vars($settings) {
  --select-insights-theme-option-descripion-color: #{map_get_strict($settings, "option-descripion-color")};
  --select-insights-theme-option-icon-color: #{map_get_strict($settings, "option-icon-color")};
}

@mixin _select-error-vars($settings) {
  --select-error-border-color: #{map_get_strict($settings, "border-color")};
  --select-error-focused-border-color: #{map_get_strict($settings, "focused-border-color")};
  --select-error-dropdown-border-color: #{map_get_strict($settings, "dropdown-border-color")};
  --select-error-dropdown-border-top-color: #{map_get_strict($settings, "dropdown-border-top-color")};
}

@mixin _select-warning-vars($settings) {
  --select-warning-border-color: #{map_get_strict($settings, "border-color")};
  --select-warning-focused-border-color: #{map_get_strict($settings, "focused-border-color")};
  --select-warning-dropdown-border-color: #{map_get_strict($settings, "dropdown-border-color")};
  --select-warning-dropdown-border-top-color: #{map_get_strict($settings, "dropdown-border-top-color")};
}
