@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --field-tag-background: #{map_get_strict($settings, "background")};
  --field-tag-border-bottom: #{map_get_strict($settings, "border-bottom")};
  --field-tag-invalid-border-color: #{map_get_strict($settings, "invalid-border-color")};
  --field-tag-text-color: #{map_get_strict($settings, "text-color")};
  --field-tag-margin: #{map_get_strict($settings, "margin")};
  --field-tag-min-width: #{map_get_strict($settings, "min-width")};
  --field-tag-padding: #{map_get_strict($settings, "padding")};
  --field-tag-disabled-color: #{map_get_strict($settings, "disabled-color")};
  --field-tag-icon-color: #{map_get_strict($settings, "icon-color")};
  --field-tag-icon-margin: #{map_get_strict($settings, "icon-margin")};
  --field-tag-invalid-color: #{map_get_strict($settings, "invalid-color")};
  --field-tag-error-background: #{map_get_strict($settings, "error-background")};
  --field-tag-error-border: #{map_get_strict($settings, "error-border")};
  --field-tag-error-color: #{map_get_strict($settings, "error-color")};
}
