@use "shared/mixins/wrap-with-root" as *;
@use "shared/mixins/map-get-strict" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _form-field-simple-hint-vars(map_get_strict($settings, "form-field-simple-hint"));
    @include _form-field-simple-label-vars(map_get_strict($settings, "form-field-simple-label"));
    @include _form-field-input-wrapper-vars(map_get_strict($settings, "form-field-input-wrapper"));
    @include _form-field-container-vars(map_get_strict($settings, "form-field-container"));
  }
}

@mixin _form-field-simple-hint-vars($settings) {
  --form-field-simple-hint-color: #{map_get_strict($settings, "color")};
  --form-field-simple-hint-font-size: #{map_get_strict($settings, "font-size")};
  --form-field-simple-hint-error-color: #{map_get_strict($settings, "error-color")};
  --form-field-simple-hint-error-link-hover-color: #{map_get_strict($settings, "error-link-hover-color")};
}

@mixin _form-field-simple-label-vars($settings) {
  --form-field-simple-label-required-flag-color: #{map_get_strict($settings, "required-flag-color")};
}

@mixin _form-field-input-wrapper-vars($settings) {
  --form-field-input-wrapper-warning-background-color: #{map_get_strict($settings, "warning-background-color")};
  --form-field-input-wrapper-warning-readonly-background-color: #{map_get_strict(
      $settings,
      "warning-readonly-background-color"
    )};
  --form-field-input-wrapper-error-background-color: #{map_get_strict($settings, "error-background-color")};
  --form-field-input-wrapper-error-readonly-background-color: #{map_get_strict(
      $settings,
      "error-readonly-background-color"
    )};
  --form-field-input-wrapper-notification-warning-color: #{map_get_strict($settings, "notification-warning-color")};
  --form-field-input-wrapper-notification-error-color: #{map_get_strict($settings, "notification-error-color")};
  --form-field-input-wrapper-notification-warning-readonly-color: #{map_get_strict(
      $settings,
      "notification-warning-readonly-color"
    )};
  --form-field-input-wrapper-notification-error-readonly-color: #{map_get_strict(
      $settings,
      "notification-error-readonly-color"
    )};
}

@mixin _form-field-container-vars($settings) {
  --form-field-container-bottom-margin: #{map_get_strict($settings, "bottom-margin")};
  --form-field-container-compact-bottom-margin: #{map_get_strict($settings, "compact-bottom-margin")};
}
