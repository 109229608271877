@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --text-field-type-background-color: #{map_get_strict($settings, "background-color")};
  --text-field-type-color: #{map_get_strict($settings, "color")};
}
