/* Theming goes first to allow easier override in global styles */
@use "theming" as *;

/* You can add global styles to this file, and also import other style files */
@use "fonts" as *;
@use "variables" as *;
@use "css-vars" as *;
@use "blocks/page-header" as *;
@use "blocks/page-content" as *;
@use "blocks/page-filters" as *;
@use "blocks/text-highlight" as *;

// We need these styles to be loaded early as we have initial spinner outside Angular App
@use "shared/components/spinner" as *;
// We need select styles to be loaded globally, because they might be used in other components (e.g. tree-select)
@use "shared/components/select" as *;
@use "shared/components/select-dropdown" as *;
@use "shared/components/label" as *;
@use "shared/components/link" as *;
@use "shared/components/page-filters" as *;
@use "shared/components/resizing" as *;
@use "shared/components/plain-text-field" as *;
@use "blocks/page-spinner" as *;

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: $lcap-brand-primary;
  color: $lcap-text-primary;
  font-family: $primary-font-family;
  font-size: $default-font-size;
  line-height: $default-line-height;
  margin: 0;
  min-height: 100vh;
  min-width: 320px;
  // Prevents layout shifts when page scrollbar appears/disappears
  overflow-y: scroll;
}

/* stylelint-disable selector-no-qualifying-type */
a {
  color: $link-primary-color;
  text-decoration: underline;

  &:hover {
    color: $link-primary-hover-color;
    text-decoration: underline;
  }

  &.disabled {
    color: $lcap-text-disabled;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }
}
/* stylelint-enable */

p {
  margin: 0 0 1em;
}

.no-scroll {
  overflow: hidden;
}
