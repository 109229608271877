@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --time-picker-color: #{map_get_strict($settings, "color")};
  --time-picker-select-dropdown-item-selected-color: #{map_get_strict($settings, "select-dropdown-item-selected-color")};
  --time-picker-select-dropdown-max-height: #{map_get_strict($settings, "select-dropdown-max-height")};
  --time-picker-select-dropdown-width: #{map_get_strict($settings, "select-dropdown-width")};
  --time-picker-select-dropdown-padding: #{map_get_strict($settings, "select-dropdown-padding")};
}
