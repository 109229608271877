@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --date-time-picker-background: #{map_get_strict($settings, "background")};
  --date-time-picker-border-color: #{map_get_strict($settings, "border-color")};
  --date-time-picker-box-shadow: #{map_get_strict($settings, "box-shadow")};
  --date-time-picker-date-picker-padding: #{map_get_strict($settings, "date-picker-padding")};
}
