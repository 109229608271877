@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  --lcap-layout-grid-border-color: #{map_get_strict($settings, "grid-border-color")};
  --lcap-layout-dnd-placeholder-background: #{map_get_strict($settings, "dnd-placeholder-background")};
  --lcap-layout-dnd-placeholder-border: #{map_get_strict($settings, "dnd-placeholder-border")};
  --lcap-layout-dnd-placeholder-border-radius: #{map_get_strict($settings, "dnd-placeholder-border-radius")};
  --lcap-layout-dnd-placeholder-color: #{map_get_strict($settings, "dnd-placeholder-color")};
  --lcap-layout-dnd-placeholder-icon-color: #{map_get_strict($settings, "dnd-placeholder-icon-color")};
  --lcap-layout-widget-shadow-background: #{map_get_strict($settings, "widget-shadow-background")};
}
