@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  --form-control-background: #{map_get_strict($settings, "background")};
  --form-control-border: #{map_get_strict($settings, "border")};
  --form-control-border-radius: #{map_get_strict($settings, "border-radius")};
  --form-control-color: #{map_get_strict($settings, "color")};
  --form-control-min-height: #{map_get_strict($settings, "min-height")};
  --form-control-padding: #{map_get_strict($settings, "padding")};
  --form-control-focus-border-color: #{map_get_strict($settings, "focus-border-color")};
  --form-control-focus-box-shadow: #{map_get_strict($settings, "focus-box-shadow")};
  --form-control-placeholder-color: #{map_get_strict($settings, "placeholder-color")};
  --form-control-disabled-background: #{map_get_strict($settings, "disabled-background")};
  --form-control-disabled-color: #{map_get_strict($settings, "disabled-color")};
  --form-control-plain-text-field-min-height: #{map_get_strict($settings, "plain-text-field-min-height")};
  --form-control-plain-text-field-compact-border-radius: #{map_get_strict(
      $settings,
      "plain-text-field-compact-border-radius"
    )};
  --form-control-plain-text-field-error-border-color: #{map_get_strict($settings, "plain-text-field-error-border-color")};
  --form-control-plain-text-field-error-focus-border-color: #{map_get_strict(
      $settings,
      "plain-text-field-error-focus-border-color"
    )};
}
