@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --link-color: #{map_get_strict($settings, "primary-color")};
  --link-hover-color: #{map_get_strict($settings, "primary-hover-color")};
  --link-active-color: #{map_get_strict($settings, "active-color")};
  --link-active-hover-color: #{map_get_strict($settings, "active-hover-color")};
  --link-brand-color: #{map_get_strict($settings, "brand-color")};
  --link-brand-hover-color: #{map_get_strict($settings, "brand-hover-color")};
  --link-neutral-color: #{map_get_strict($settings, "neutral-color")};
  --link-neutral-hover-color: #{map_get_strict($settings, "neutral-hover-color")};
  --link-secondary-color: #{map_get_strict($settings, "secondary-color")};
  --link-secondary-hover-color: #{map_get_strict($settings, "secondary-hover-color")};
  --link-download-color: #{map_get_strict($settings, "download-color")};
  --link-download-underline-color: #{map_get_strict($settings, "download-underline-color")};
  --link-download-underline-hover-color: #{map_get_strict($settings, "download-underline-hover-color")};
}
