@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  .button {
    @include _sizes(map_get_strict($settings, "sizes"));
    @include _themes(map_get($settings, "themes"));

    @content;
  }

  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --button-font-family: #{map_get_strict($settings, "font-family")};
  --button-border-radius: #{map_get_strict($settings, "border-radius")};
}

@mixin size-vars($sizes-definition) {
  --button-font-size: #{map_get_strict($sizes-definition, "font-size")};
  --button-font-weight: #{map_get_strict($sizes-definition, "font-weight")};
  --button-height: #{map_get_strict($sizes-definition, "height")};
  --button-padding-horizontal: #{map_get_strict($sizes-definition, "padding-horizontal")};
  --button-icon-size: #{map_get_strict($sizes-definition, "icon-size")};
  --button-icon-margin: #{map_get_strict($sizes-definition, "icon-margin")};
  --button-icon-padding: #{map_get_strict($sizes-definition, "icon-padding")};
}

@mixin main-type-vars($theme) {
  $theme: map-merge(
    (
      "opacity": 1,
      "disabled-opacity": 1,
      "pending-opacity": 1,
    ),
    $theme
  );

  --button-background-color: #{map_get_strict($theme, "background-color")};
  --button-color: #{map_get_strict($theme, "color")};
  --button-opacity: #{map_get_strict($theme, "opacity")};
  --button-border-color: #{map_get_strict($theme, "border-color")};
  --button-hover-background-color: #{map_get_strict($theme, "hover-background-color")};
  --button-hover-color: #{map_get_strict($theme, "hover-color")};
  --button-hover-border-color: #{map_get_strict($theme, "hover-background-color")};
  --button-disabled-background-color: #{map_get_strict($theme, "disabled-background-color")};
  --button-disabled-color: #{map_get_strict($theme, "disabled-color")};
  --button-disabled-border-color: #{map_get_strict($theme, "disabled-background-color")};
  --button-disabled-opacity: #{map_get_strict($theme, "disabled-opacity")};
  --button-pending-background-color: #{map_get_strict($theme, "pending-background-color")};
  --button-pending-border-color: #{map_get_strict($theme, "pending-background-color")};
  --button-pending-opacity: #{map_get_strict($theme, "pending-opacity")};
}

@mixin outline-type-vars($theme) {
  --button-background-color: #{map_get_strict($theme, "background-color")};
  --button-color: #{map_get_strict($theme, "color")};
  --button-border-color: #{map_get_strict($theme, "border-color")};
  --button-hover-background-color: #{map_get_strict($theme, "hover-background-color")};
  --button-hover-color: #{map_get_strict($theme, "hover-color")};
  --button-hover-border-color: #{map_get_strict($theme, "hover-border-color")};
  --button-disabled-background-color: #{map_get_strict($theme, "disabled-background-color")};
  --button-disabled-color: #{map_get_strict($theme, "disabled-color")};
  --button-disabled-border-color: #{map_get_strict($theme, "disabled-border-color")};
  --button-pending-background-color: #{map_get_strict($theme, "pending-background-color")};
  --button-pending-border-color: #{map_get_strict($theme, "pending-border-color")};
}

@mixin flat-type-vars($theme) {
  --button-background-color: transparent;
  --button-color: #{map_get_strict($theme, "color")};
  --button-border-color: transparent;
  --button-hover-background-color: #{map_get_strict($theme, "hover-background-color")};
  --button-hover-color: #{map_get_strict($theme, "hover-color")};
  --button-hover-border-color: #{map_get_strict($theme, "hover-background-color")};
  --button-disabled-background-color: transparent;
  --button-disabled-color: #{map_get_strict($theme, "disabled-color")};
  --button-disabled-border-color: transparent;
  --button-pending-background-color: transparent;
  --button-pending-border-color: transparent;
}

@mixin theme($types-map, $theme-name: "default") {
  @if $theme-name == "default" {
    @include _types($types-map);
  } @else {
    &_#{$theme-name} {
      @include _types($types-map);
    }
  }
}

@mixin _sizes($sizes-definitions) {
  @each $name, $definitions in $sizes-definitions {
    @if $name == "default" {
      @include size-vars($definitions);
    } @else {
      &_#{$name} {
        @include size-vars($definitions);
      }
    }
  }
}

@mixin _themes($themes) {
  @if $themes {
    @each $theme-name, $theme in $themes {
      @include theme(map_get($themes, $theme-name), $theme-name);
    }
  }
}

@mixin _types($types-map) {
  @each $type-name, $type-vars in $types-map {
    @if $type-name == "main" {
      @include main-type-vars($type-vars);
    }

    @if $type-name == "outline" {
      &.button_outline {
        @include outline-type-vars($type-vars);
      }
    }

    @if $type-name == "flat" {
      &.button_flat {
        @include flat-type-vars($type-vars);
      }
    }
  }
}
