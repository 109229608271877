@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --trimmed-list-popup-background: #{map_get_strict($settings, "popup-background")};
  --trimmed-list-popup-border: #{map_get_strict($settings, "popup-border")};
  --trimmed-list-popup-box-shadow: #{map_get_strict($settings, "popup-box-shadow")};
}
