@use "shared/lcap/variables/colors" as *;
@use "shared/lcap/variables/text" as *;

$h1-font-size: 28px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 16px;
$h5-font-size: 14px;
$h6-font-size: 14px;
$primary-font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
$default-font-size: 14px;
$default-line-height: 1.4286;
