@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

$selector: ".data-table";

@mixin base($settings) {
  #{$selector} {
    @content;
  }

  @include wrap-with-root {
    @include _base-vars($settings);
    @include _column-attributes-vars(map_get_strict($settings, "column-attributes"));
    @include _column-attributes-popup-vars(map_get_strict($settings, "column-attributes-popup"));
    @include _drop-preview-vars(map_get_strict($settings, "drop-preview"));
    @include _footer-controls-cell-vars(map_get_strict($settings, "footer-controls-cell"));
    @include _header-controls-cell-vars(map_get_strict($settings, "header-controls-cell"));
    @include _date-editor-vars(map_get_strict($settings, "date-editor"));
    @include _text-editor-vars(map_get_strict($settings, "text-editor"));
    @include _file-editor-vars(map_get_strict($settings, "file-editor"));
    @include _row-controls-cell-vars(map_get_strict($settings, "row-controls-cell"));
    @include _row-index-cell-vars(map_get_strict($settings, "row-index-cell"));
    @include _header-cell-vars(map_get_strict($settings, "header-cell"));
    @include _row-cell-vars(map_get_strict($settings, "row-cell"));
    @include _focused-cell-vars(map_get_strict($settings, "focused-cell"));
    @include _file-preview-vars(map_get_strict($settings, "file-preview"));
    @include _column-title-vars(map_get_strict($settings, "column-title"));
    @include _skeleton-vars(map_get_strict($settings, "skeleton"));
    @include _set-vars(map_get_strict($settings, "set"));
  }
}

@mixin _base-vars($settings) {
  --data-table-color: #{map_get_strict($settings, "color")};
  --data-table-border-color: #{map_get_strict($settings, "border-color")};
  --data-table-border-radius: #{map_get_strict($settings, "border-radius")};
  --data-table-footer-background: #{map_get_strict($settings, "footer-background")};
  --data-table-footer-border-color: #{map_get_strict($settings, "footer-border-color")};
  --data-table-footer-stuck-background: #{map_get_strict($settings, "footer-stuck-background")};
  --data-table-empty-header-cell-background: #{map_get_strict($settings, "empty-header-cell-background")};
  --data-table-cell-background: #{map_get_strict($settings, "cell-background")};
  --data-table-cell-select-value-color: #{map_get_strict($settings, "cell-select-value-color")};
  --data-table-scrollbar-color: #{map_get_strict($settings, "scrollbar-color")};
  --data-table-scrollbar-hover-color: #{map_get_strict($settings, "scrollbar-hover-color")};
}

@mixin _column-attributes-vars($settings) {
  --data-table-column-attributes-select-option-icon-color: #{map_get_strict($settings, "select-option-icon-color")};
  --data-table-column-attributes-switch-wrapper-disabled-color: #{map_get_strict(
      $settings,
      "switch-wrapper-disabled-color"
    )};
}

@mixin _column-attributes-popup-vars($settings) {
  --data-table-column-attributes-popup-background: #{map_get_strict($settings, "background")};
  --data-table-column-attributes-popup-border-color: #{map_get_strict($settings, "border-color")};
  --data-table-column-attributes-popup-border-radius: #{map_get_strict($settings, "border-radius")};
  --data-table-column-attributes-popup-shadow: #{map_get_strict($settings, "box-shadow")};
  --data-table-column-attributes-popup-footer-background: #{map_get_strict($settings, "footer-background")};
  --data-table-column-attributes-popup-footer-border-radius: #{map_get_strict($settings, "footer-border-radius")};
  --data-table-column-attributes-popup-footer-color: #{map_get_strict($settings, "footer-color")};
  --data-table-column-attributes-popup-footer-icon-color: #{map_get_strict($settings, "footer-icon-color")};
}

@mixin _drop-preview-vars($settings) {
  --data-table-drop-preview-background: #{map_get_strict($settings, "background")};
  --data-table-drop-preview-after-background: #{map_get_strict($settings, "after-background")};
}

@mixin _footer-controls-cell-vars($settings) {
  --data-table-footer-controls-cell-background: #{map_get_strict($settings, "background")};
  --data-table-footer-controls-cell-button-background: #{map_get_strict($settings, "button-background")};
  --data-table-footer-controls-cell-button-color: #{map_get_strict($settings, "button-color")};
  --data-table-footer-controls-cell-button-hover-background: #{map_get_strict($settings, "button-hover-background")};
  --data-table-footer-controls-cell-button-hover-color: #{map_get_strict($settings, "button-hover-color")};
  --data-table-footer-controls-cell-button-disabled-color: #{map_get_strict($settings, "button-disabled-color")};
}

@mixin _header-controls-cell-vars($settings) {
  --data-table-header-controls-cell-background: #{map_get_strict($settings, "background")};
  --data-table-header-controls-cell-button-background: #{map_get_strict($settings, "button-background")};
  --data-table-header-controls-cell-button-color: #{map_get_strict($settings, "button-color")};
  --data-table-header-controls-cell-button-hover-background: #{map_get_strict($settings, "button-hover-background")};
  --data-table-header-controls-cell-button-hover-color: #{map_get_strict($settings, "button-hover-color")};
  --data-table-header-controls-cell-button-disabled-color: #{map_get_strict($settings, "button-disabled-color")};
}

@mixin _date-editor-vars($settings) {
  --data-table-date-editor-input-border-radius: #{map_get_strict($settings, "input-border-radius")};
  --data-table-date-editor-input-with-bg-background: #{map_get_strict($settings, "with-bg-background")};
}

@mixin _text-editor-vars($settings) {
  --data-table-text-editor-scrollbar-color: #{map_get_strict($settings, "scrollbar-color")};
  --data-table-text-editor-scrollbar-hover-color: #{map_get_strict($settings, "scrollbar-hover-color")};
}

@mixin _file-editor-vars($settings) {
  --data-table-file-editor-highlight-background: #{map_get_strict($settings, "highlight-background")};
  --data-table-file-editor-drop-zone-color: #{map_get_strict($settings, "drop-zone-color")};
  --data-table-file-editor-drop-zone-active-color: #{map_get_strict($settings, "drop-zone-active-color")};
  --data-table-file-editor-drop-icon-background: #{map_get_strict($settings, "drop-icon-background")};
  --data-table-file-editor-drop-icon-border-radius: #{map_get_strict($settings, "drop-icon-border-radius")};
  --data-table-file-editor-drop-icon-color: #{map_get_strict($settings, "drop-icon-color")};
}

@mixin _row-controls-cell-vars($settings) {
  --data-table-row-controls-cell-button-background: #{map_get_strict($settings, "button-background")};
  --data-table-row-controls-cell-button-border-radius: #{map_get_strict($settings, "button-border-radius")};
  --data-table-row-controls-cell-button-color: #{map_get_strict($settings, "button-color")};
  --data-table-row-controls-cell-button-save-color: #{map_get_strict($settings, "button-save-color")};
  --data-table-row-controls-cell-button-save-hover-background: #{map_get_strict(
      $settings,
      "button-save-hover-background"
    )};
  --data-table-row-controls-cell-button-save-hover-color: #{map_get_strict($settings, "button-save-hover-color")};
  --data-table-row-controls-cell-button-discard-color: #{map_get_strict($settings, "button-discard-color")};
  --data-table-row-controls-cell-button-discard-hover-background: #{map_get_strict(
      $settings,
      "button-discard-hover-background"
    )};
  --data-table-row-controls-cell-button-discard-hover-color: #{map_get_strict($settings, "button-discard-hover-color")};
}

@mixin _row-index-cell-vars($settings) {
  --data-table-row-index-cell-color: #{map_get_strict($settings, "color")};
  --data-table-row-index-cell-disabled-color: #{map_get_strict($settings, "disabled-color")};
  --data-table-row-index-cell-row-active-color: #{map_get_strict($settings, "row-active-color")};
  --data-table-row-index-cell-error-background: #{map_get_strict($settings, "error-background")};
  --data-table-row-index-cell-error-color: #{map_get_strict($settings, "error-color")};
  --data-table-row-index-cell-hover-background: #{map_get_strict($settings, "hover-background")};
  --data-table-row-index-cell-row-default-background: #{map_get_strict($settings, "row-default-background")};
  --data-table-row-index-cell-row-hover-background: #{map_get_strict($settings, "row-hover-background")};
  --data-table-row-index-cell-row-focused-background: #{map_get_strict($settings, "row-focused-background")};
  --data-table-row-index-cell-row-selected-background: #{map_get_strict($settings, "row-selected-background")};
  --data-table-row-index-cell-row-edited-background: #{map_get_strict($settings, "row-edited-background")};
}

@mixin _header-cell-vars($settings) {
  --data-table-header-cell-background: #{map_get_strict($settings, "background")};
  --data-table-header-cell-color: #{map_get_strict($settings, "color")};
  --data-table-header-cell-hover-background: #{map_get_strict($settings, "hover-background")};
  --data-table-header-cell-hover-color: #{map_get_strict($settings, "hover-color")};
  --data-table-header-cell-selected-background: #{map_get_strict($settings, "selected-background")};
  --data-table-header-cell-selected-color: #{map_get_strict($settings, "selected-color")};
  --data-table-header-cell-hint-border-color: #{map_get_strict($settings, "hint-border-color")};
  --data-table-header-cell-hint-selected-border-color: #{map_get_strict($settings, "hint-selected-border-color")};
  --data-table-header-cell-sort-button-border-radius: #{map_get_strict($settings, "sort-button-border-radius")};
  --data-table-header-cell-sort-button-hover-background: #{map_get_strict($settings, "sort-button-hover-background")};
  --data-table-header-cell-sort-button-selected-hover-background: #{map_get_strict(
      $settings,
      "sort-button-selected-hover-background"
    )};
  --data-table-header-cell-menu-trigger-color: #{map_get_strict($settings, "menu-trigger-color")};
  --data-table-header-cell-menu-trigger-hover-color: #{map_get_strict($settings, "menu-trigger-hover-color")};
  --data-table-header-cell-menu-trigger-selected-color: #{map_get_strict($settings, "menu-trigger-selected-color")};
}

@mixin _row-cell-vars($settings) {
  --data-table-column-cell-outline-border-color: #{map_get_strict($settings, "outline-border-color")};
  --data-table-row-cell-default-background: #{map_get_strict($settings, "default-background")};
  --data-table-row-cell-readonly-background: #{map_get_strict($settings, "readonly-background")};
  --data-table-row-cell-hover-background: #{map_get_strict($settings, "hover-background")};
  --data-table-row-cell-focused-background: #{map_get_strict($settings, "focused-background")};
  --data-table-row-cell-selected-background: #{map_get_strict($settings, "selected-background")};
  --data-table-row-cell-edited-background: #{map_get_strict($settings, "edited-background")};
  --data-table-row-cell-value-disabled-color: #{map_get_strict($settings, "value-disabled-color")};
  --data-table-row-cell-value-error-color: #{map_get_strict($settings, "value-error-color")};
  --data-table-row-cell-value-light-color: #{map_get_strict($settings, "value-light-color")};
  --data-table-row-cell-value-null-font-family: #{map_get_strict($settings, "value-null-font-family")};
  --data-table-row-cell-column-selected-background: #{map_get_strict($settings, "selected-column-background")};
  --data-table-row-cell-error-icon-color: #{map_get_strict($settings, "error-icon-color")};
  --data-table-row-cell-value-scrollbar-color: #{map_get_strict($settings, "value-scrollbar-color")};
  --data-table-row-cell-value-scrollbar-hover-color: #{map_get_strict($settings, "value-scrollbar-hover-color")};
}

@mixin _focused-cell-vars($settings) {
  --data-table-focused-cell-color: #{map_get_strict($settings, "color")};
  --data-table-focused-cell-background: #{map_get_strict($settings, "background")};
  --data-table-focused-cell-shadow-color: #{map_get_strict($settings, "shadow-color")};
  --data-table-focused-cell-selected-background: #{map_get_strict($settings, "selected-background")};
  --data-table-focused-cell-active-shadow: #{map_get_strict($settings, "active-shadow")};
}

@mixin _file-preview-vars($settings) {
  --data-table-file-preview-background: #{map_get_strict($settings, "background")};
  --data-table-file-preview-border-radius: #{map_get_strict($settings, "border-radius")};
  --data-table-file-preview-shadow-color: #{map_get_strict($settings, "shadow-color")};
  --data-table-file-preview-color: #{map_get_strict($settings, "color")};
  --data-table-file-preview-name-color: #{map_get_strict($settings, "name-color")};
  --data-table-file-preview-button-border-radius: #{map_get_strict($settings, "button-border-radius")};
  --data-table-file-preview-button-color: #{map_get_strict($settings, "button-color")};
  --data-table-file-preview-button-active-background: #{map_get_strict($settings, "button-active-background")};
  --data-table-file-preview-button-active-color: #{map_get_strict($settings, "button-active-color")};
  --data-table-file-preview-button-separator-background: #{map_get_strict($settings, "button-separator-background")};
  --data-table-file-preview-button-separator-border-radius: #{map_get_strict(
      $settings,
      "button-separator-border-radius"
    )};
}

@mixin _column-title-vars($settings) {
  --data-table-column-title-icon-color: #{map_get_strict($settings, "icon-color")};
  --data-table-column-title-note-color: #{map_get_strict($settings, "note-color")};
  --data-table-column-title-required-flag-color: #{map_get_strict($settings, "required-flag-color")};
  --data-table-column-title-selected-icon-color: #{map_get_strict($settings, "selected-icon-color")};
  --data-table-column-title-selected-note-color: #{map_get_strict($settings, "selected-note-color")};
  --data-table-column-title-selected-required-flag-color: #{map_get_strict($settings, "selected-required-flag-color")};
  --data-table-column-title-hover-icon-color: #{map_get_strict($settings, "hover-icon-color")};
}

@mixin _skeleton-vars($settings) {
  --data-table-skeleton-header-cell-content-background: #{map_get_strict($settings, "header-cell-content-background")};
  --data-table-skeleton-row-cell-content-background: #{map_get_strict($settings, "row-cell-content-background")};
}

@mixin _set-vars($settings) {
  --data-table-set-tag-border: #{map_get_strict($settings, "tag-border")};
  --data-table-set-tag-background: #{map_get_strict($settings, "tag-background")};
  --data-table-set-tag-squared-border-bottom: #{map_get_strict($settings, "tag-squared-border-bottom")};
  --data-table-set-trimmed-list-color: #{map_get_strict($settings, "trimmed-list-color")};
}
