@use "variables" as *;
@use "shared/mixins/media-queries" as *;

.page-content {
  box-sizing: content-box;
  display: flex;
  flex: 1;
  min-height: $page-content-min-height;
  min-width: 0;
  padding: 32px;

  @include device(tablet) {
    display: block;
  }

  &_single {
    display: block;
  }

  &__main {
    flex: 1 1 auto;
    min-width: 0;
    order: 1;
  }

  &__aside {
    flex-shrink: 0;
    order: 2;
    width: 360px;

    @include device(tablet) {
      max-width: none;
      width: 100%;
    }
  }

  &__spinner {
    display: flex;
    justify-content: center;
    margin: 30px auto;
  }
}
