@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  --kpi-vis-label-color: #{map_get_strict($settings, "label-color")};
  --kpi-vis-label-font-size: #{map_get_strict($settings, "label-font-size")};
  --kpi-vis-value-color: #{map_get_strict($settings, "value-color")};
  --kpi-vis-null-value-color: #{map_get_strict($settings, "null-value-color")};
}
