@use "../variables/screen-sizes" as *;

@mixin device($type) {
  @if $type == phone {
    @media (max-width: $screen-width-phone) {
      @content;
    }
  } @else if $type == phablet {
    @media (max-width: $screen-width-phablet) {
      @content;
    }
  } @else if $type == tablet {
    @media (max-width: $screen-width-tablet) {
      @content;
    }
  } @else if $type == compact {
    @media (max-width: $screen-width-compact) {
      @content;
    }
  } @else if $type == desktop-small {
    @media (max-width: $screen-width-desktop-small) {
      @content;
    }
  } @else if $type == desktop-hd {
    @media (max-width: $screen-width-desktop-hd) {
      @content;
    }
  } @else if $type == desktop-quad-hd {
    @media (max-width: $screen-width-desktop-quad-hd) {
      @content;
    }
  } @else if $type == desktop-full-hd {
    @media (max-width: $screen-width-desktop-full-hd) {
      @content;
    }
  }
}

@mixin device-min($type) {
  @if $type == phone {
    @media (min-width: $screen-width-phone) {
      @content;
    }
  } @else if $type == phablet {
    @media (min-width: $screen-width-phablet) {
      @content;
    }
  } @else if $type == tablet {
    @media (min-width: $screen-width-tablet) {
      @content;
    }
  } @else if $type == compact {
    @media (min-width: $screen-width-compact) {
      @content;
    }
  } @else if $type == desktop-small {
    @media (min-width: $screen-width-desktop-small) {
      @content;
    }
  } @else if $type == desktop-hd {
    @media (min-width: $screen-width-desktop-hd) {
      @content;
    }
  } @else if $type == desktop-full-hd {
    @media (min-width: $screen-width-desktop-full-hd) {
      @content;
    }
  }
}
