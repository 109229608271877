@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --date-picker-padding: #{map_get_strict($settings, "padding")};
  --date-picker-active-color: #{map_get_strict($settings, "active-color")};
  --date-picker-active-bg-color: #{map_get_strict($settings, "active-bg-color")};
  --date-picker-active-selected-bg-color: #{map_get_strict($settings, "active-selected-bg-color")};
  --date-picker-active-in-range-bg-color: #{map_get_strict($settings, "active-in-range-bg-color")};
  --date-picker-in-range-color: #{map_get_strict($settings, "in-range-color")};
  --date-picker-in-range-bg-color: #{map_get_strict($settings, "in-range-bg-color")};
  --date-picker-selected-color: #{map_get_strict($settings, "selected-color")};
  --date-picker-selected-bg-color: #{map_get_strict($settings, "selected-bg-color")};
  --date-picker-selected-disabled-color: #{map_get_strict($settings, "selected-disabled-color")};
  --date-picker-outline-color: #{map_get_strict($settings, "outline-color")};
  --date-picker-border-radius: #{map_get_strict($settings, "border-radius")};
  --date-picker-month-select-color: #{map_get_strict($settings, "month-select-color")};
  --date-picker-weekday-color: #{map_get_strict($settings, "weekday-color")};
  --date-picker-day-color: #{map_get_strict($settings, "day-color")};
  --date-picker-day-today-border-color: #{map_get_strict($settings, "day-today-border-color")};
  --date-picker-day-disabled-color: #{map_get_strict($settings, "day-disabled-color")};
  --date-picker-day-disabled-cursor: #{map_get_strict($settings, "day-disabled-cursor")};
  --date-picker-day-outside-color: #{map_get_strict($settings, "day-outside-color")};
  --date-picker-white-font-pop-color: #{map_get_strict($settings, "white-font-pop-color")};
  --date-picker-pop-color: #{map_get_strict($settings, "pop-color")};
}
