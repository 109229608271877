@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    --table-resize-handle-background: #{map_get_strict($settings, "background")};
    --table-resize-handle-background-muted: #{map_get_strict($settings, "background-muted")};
    --table-resize-handle-border-radius: #{map_get_strict($settings, "border-radius")};
  }
}
