@use "variables" as *;
@use "shared/lcap/variables" as *;
@use "shared/theming-mixins/horizontal-tab-navigation";

$container-padding: 32px;

$horizontal-tab-navigation-theme: (
  "tab": (
    "padding": 16px,
    "active-color": $lcap-brand-primary,
    "inactive-color": $lcap-text-secondary,
    "content-max-width": 280px,
    "bar-height": 3px,
    "bar-hover-color": $lcap-neutral-border,
    "active-bar-background": $lcap-brand-primary,
    "font-size": 16px,
  ),
  "container": (
    "box-shadow": $lcap-border-default,
    "padding-left": $container-padding,
    "padding-right": $container-padding,
    "tablet-padding-left": $container-padding,
    "tablet-padding-right": $container-padding,
  ),
  "menu": (
    "background": $lcap-surface-default,
    "border-color": $lcap-border-default,
    "border-radius": $border-radius-s,
    "box-shadow": $dropdown-shadow,
    "item-color": $lcap-text-primary,
    "item-hover-color": $lcap-surface-subtle,
  ),
  "indicator": (
    "border-radius": $border-radius-xs $border-radius-xs 0 0,
  ),
);

@mixin theming {
  @include horizontal-tab-navigation.base($horizontal-tab-navigation-theme);

  .horizontal-tab-navigation__container {
    padding-left: $container-padding;
    padding-right: $container-padding;
  }
}
