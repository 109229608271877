@use "shared/mixins/button-focus-outline" as *;
@use "shared/mixins/reset-button" as *;

@mixin button-pseudo-link(
  $color,
  $hover-color,
  $focus-color: $hover-color,
  $active-color: $hover-color,
  $text-decoration: underline
) {
  @include reset-button;

  @if $color {
    color: $color;
  }

  cursor: pointer;
  text-decoration: $text-decoration;
  transition: color 0.2s ease;

  @if $hover-color {
    &:hover {
      color: $hover-color;
    }
  }

  &:focus {
    @if $focus-color {
      color: $focus-color;
    }
  }

  @if $active-color {
    &:active {
      color: $active-color;
    }
  }

  @if $text-decoration == "hover" {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include button-focus-outline;
}
