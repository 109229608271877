@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    --table-border-color: #{map_get_strict($settings, "border-color")};
    --table-border-radius: #{map_get_strict($settings, "border-radius")};
    --table-sticky-box-shadow: #{map_get_strict($settings, "sticky-box-shadow")};
    --table-row-background: #{map_get_strict($settings, "row-background")};
    --table-row-hover-background: #{map_get_strict($settings, "row-hover-background")};
    --table-row-highlight-background: #{map_get_strict($settings, "row-highlight-background")};
    --table-row-selected-background: #{map_get_strict($settings, "row-selected-background")};
    --table-row-muted-background: #{map_get_strict($settings, "row-muted-background")};
    --table-row-muted-stripe-color: #{map_get_strict($settings, "row-muted-stripe-color")};
    --table-row-muted-stripe-background: #{map_get_strict($settings, "row-muted-stripe-background")};
    --table-row-link-color: #{map_get_strict($settings, "row-link-color")};
    --table-cell-color: #{map_get_strict($settings, "cell-color")};
    --table-cell-border-color: #{map_get_strict($settings, "cell-border-color")};
    --table-cell-padding-vertical: #{map_get_strict($settings, "cell-padding-vertical")};
    --table-cell-padding-horizontal: #{map_get_strict($settings, "cell-padding-horizontal")};
    --table-header-background: #{map_get_strict($settings, "header-background")};
    --table-header-cell-description-color: #{map_get_strict($settings, "header-cell-description-color")};
    --table-dropdown-menu-border-radius: #{map_get_strict($settings, "dropdown-menu-border-radius")};
    --table-dropdown-menu-trigger-icon-color: #{map_get_strict($settings, "dropdown-menu-trigger-icon-color")};
    --table-dropdown-menu-trigger-icon-hover-color: #{map_get_strict(
        $settings,
        "dropdown-menu-trigger-icon-hover-color"
      )};
    --table-card-background: #{map_get_strict($settings, "card-background")};
    --table-card-hover-background: #{map_get_strict($settings, "card-hover-background")};
    --table-card-shadow: #{map_get_strict($settings, "card-shadow")};
    --table-card-hover-shadow: #{map_get_strict($settings, "card-hover-shadow")};
    --table-checkbox-selected-background: #{map_get_strict($settings, "checkbox-selected-background")};
    --table-checkbox-selected-hover-background: #{map_get_strict($settings, "checkbox-selected-hover-background")};
    --table-checkbox-border-radius: #{map_get_strict($settings, "checkbox-border-radius")};
    --table-selected-border-color: #{map_get_strict($settings, "selected-border-color")};
    --table-selected-hover-border-color: #{map_get_strict($settings, "selected-hover-border-color")};
    --table-editable-text-border-color: #{map_get_strict($settings, "editable-text-border-color")};
    --table-editable-text-hover-border-color: #{map_get_strict($settings, "editable-text-hover-border-color")};
    --table-editable-text-placeholder-color: #{map_get_strict($settings, "editable-text-placeholder-color")};
    --table-muted-cell-border: #{map_get_strict($settings, "muted-cell-border")};
  }
}
