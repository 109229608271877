@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    --sort-icon-background: #{map_get_strict($settings, "background")};
    --sort-icon-hover-background: #{map_get_strict($settings, "hover-background")};
    --sort-icon-border-radius: #{map_get_strict($settings, "border-radius")};
  }
}
