@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --file-type-icon-image-background: #{map_get_strict($settings, "image-background")};
  --file-type-icon-image-color: #{map_get_strict($settings, "image-color")};
  --file-type-icon-json-background: #{map_get_strict($settings, "json-background")};
  --file-type-icon-json-color: #{map_get_strict($settings, "json-color")};
  --file-type-icon-text-background: #{map_get_strict($settings, "text-background")};
  --file-type-icon-text-color: #{map_get_strict($settings, "text-color")};
  --file-type-icon-csv-background: #{map_get_strict($settings, "csv-background")};
  --file-type-icon-csv-color: #{map_get_strict($settings, "csv-color")};
  --file-type-icon-excel-background: #{map_get_strict($settings, "excel-background")};
  --file-type-icon-excel-color: #{map_get_strict($settings, "excel-color")};
  --file-type-icon-other-background: #{map_get_strict($settings, "other-background")};
  --file-type-icon-other-color: #{map_get_strict($settings, "other-color")};
}
