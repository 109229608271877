@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  --chart-grid-line-color: #{map_get_strict($settings, "grid-line-color")};
  --chart-font-color: #{map_get_strict($settings, "font-color")};
  --chart-font-family: #{map_get_strict($settings, "font-family")};
  --chart-font-size: #{map_get_strict($settings, "font-size")};
  --chart-background-color: #{map_get_strict($settings, "background-color")};
  --chart-tooltip-background-color: #{map_get_strict($settings, "tooltip-background-color")};
  --chart-tooltip-border-color: #{map_get_strict($settings, "tooltip-border-color")};
  --chart-tooltip-color: #{map_get_strict($settings, "tooltip-color")};
  --chart-tooltip-null-color: #{map_get_strict($settings, "tooltip-null-color")};
  --chart-crosshair-color: #{map_get_strict($settings, "crosshair-color")};
  --chart-hovered-point-line-color: #{map_get_strict($settings, "hovered-point-line-color")};
  --chart-null-value-color: #{map_get_strict($settings, "null-value-color")};
  --chart-color-0: #{map_get_strict($settings, "color-0")};
  --chart-color-1: #{map_get_strict($settings, "color-1")};
  --chart-color-2: #{map_get_strict($settings, "color-2")};
  --chart-color-3: #{map_get_strict($settings, "color-3")};
  --chart-color-4: #{map_get_strict($settings, "color-4")};
  --chart-color-5: #{map_get_strict($settings, "color-5")};
  --chart-color-6: #{map_get_strict($settings, "color-6")};
  --chart-color-7: #{map_get_strict($settings, "color-7")};
  --chart-color-8: #{map_get_strict($settings, "color-8")};
  --chart-color-9: #{map_get_strict($settings, "color-9")};
  --chart-color-10: #{map_get_strict($settings, "color-10")};
  --chart-color-11: #{map_get_strict($settings, "color-11")};
  --chart-color-12: #{map_get_strict($settings, "color-12")};
  --chart-color-13: #{map_get_strict($settings, "color-13")};
}
