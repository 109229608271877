/* stylelint-disable */
@mixin break-word {
  // FF
  overflow-wrap: anywhere;
  // Webkit
  word-break: break-word;
  // IE
  -ms-word-break: break-all;
}
/* stylelint-enable */
