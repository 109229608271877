@use "button-focus-outline" as *;

@mixin reset-button {
  @include button-focus-outline;

  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  background: none;
  border: none;
  padding: 0;
  white-space: nowrap;
}
