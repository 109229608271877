@forward "shared/variables/easing-functions";

$layout-padding: var(--lcap-layout-padding, 0);
$header-height: var(--lcap-header-height, 52px);
$page-content-min-height: 600px;
$header-z-index: 1000;

$primary-hover-transition:
  border-color 0.3s ease,
  box-shadow 0.3s ease,
  background-color 0.3s ease;
