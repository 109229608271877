@use "variables" as *;
@use "shared/mixins/media-queries" as *;

.page-filters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 8px 0;

  &__item {
    margin: 0 8px 8px 0;

    @include device(desktop-small) {
      display: flex;
    }

    &_search {
      flex: 0 1 288px;

      @include device(desktop-small) {
        display: block;
        flex-basis: 100%;
        width: 100%;
      }
    }

    &_left {
      border-right: 1px solid $lcap-border-default;
      margin-right: 8px;
      padding-right: 8px;

      @include device(desktop-small) {
        border-bottom: 1px solid $lcap-border-default;
        border-right: none;
        margin: 0 0 12px;
        padding: 0 0 12px;
        width: 100%;
      }
    }

    &_right {
      margin-left: auto;

      @include device(desktop-small) {
        border-top: 1px solid $lcap-border-default;
        margin: 4px 0 12px;
        margin-left: 0;
        padding: 12px 0 0;
        width: 100%;
      }
    }

    &_separator {
      @include device-min(desktop-small) {
        border-left: 1px solid $lcap-border-default;
        padding-left: 8px;
      }
    }
  }
}
