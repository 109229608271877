/* stylelint-disable color-no-hex, function-disallowed-list, scss/dollar-variable-pattern */
// Neutral
$N00: #fff;
$N020: #fafbfc;
$N030: #f7f9fa;
$N040: #f3f5f6;
$N050: #e4e8eb;
$N075: #ced5db;
$N100: #bac4cc;
$N200: #92a3ad;
$N300: #637a87;
$N400: #476270;
$N500: #24434f;
$N600: #133440;
$N800: #04141a;
$N400-D: #2d3d46;
$N500-D: #25333a;
$N600-D: #1e282e;
$N700-D: #1a2328;
$N750-D: #161e22;
$N800-D: #11171a;
$N900-D: #090d0e;

// Teal
$T020: #eef8fa;
$T050: #e3f6f6;
$T100: #c7eded;
$T200: #6dc8ce;
$T300: #44afb7;
$T400: #108291;
$T500: #065f69;
$T600: #08434a;
$T700: #063338;
$T800: #021f23;
$T700-D: #1b3437;
$T800-D: #15272a;

// Green
$G020: #fbfff5;
$G050: #f0fae7;
$G100: #e0f5cf;
$G200: #c0e99f;
$G300: #9ad86a;
$G400: #77bf41;
$G500: #5aa028;
$G600: #44791e;
$G700: #366218;
$G800: #254210;
$G700-D: #27381a;
$G800-D: #1e2b14;

// Blue
$B020: #f5fbff;
$B050: #e7f2fa;
$B100: #c8e2f8;
$B200: #8fc1f1;
$B300: #68abec;
$B400: #1f75d7;
$B500: #0d4e9e;
$B600: #00295b;
$B700: #002047;
$B800: #001734;
$B700-D: #1e354e;
$B800-D: #162739;

// Purple
$P020: #f6f5ff;
$P050: #ece9fa;
$P100: #d9d3f5;
$P200: #b7a9ea;
$P300: #9d89e2;
$P400: #6d53c0;
$P500: #533aa2;
$P600: #2e205a;
$P700: #271b4c;
$P800: #1f163d;
$P700-D: #31294d;
$P800-D: #292241;

// Orange
$O020: #fffbf5;
$O050: #faf0e3;
$O100: #ffe2c1;
$O200: #ffbb75;
$O300: #ffa74e;
$O400: #e67009;
$O500: #bd5200;
$O600: #562a04;
$O700: #432103;
$O800: #311802;
$O700-D: #3d2b1b;
$O800-D: #302215;

// Red
$R020: #fff1f0;
$R050: #faebe9;
$R100: #fcded9;
$R200: #f4a293;
$R300: #f18470;
$R400: #da3b1c;
$R500: #b6270a;
$R600: #5b1203;
$R700: #480e02;
$R800: #350b02;
$R700-D: #3f2520;
$R800-D: #321e1a;

// Brown
$BR020: #f5ede5;
$BR050: #f2e6dd;
$BR100: #e2cfc2;
$BR200: #c4a696;
$BR300: #af8670;
$BR400: #724e3c;
$BR500: #583c2e;
$BR600: #4b3428;
$BR700: #3f2b21;
$BR800: #32221a;

// Rust
$RS020: #faf1e5;
$RS050: #f2e2d0;
$RS100: #ebd2ba;
$RS200: #daaa7b;
$RS300: #ca8748;
$RS400: #ab5b14;
$RS500: #884810;
$RS600: #562e0a;
$RS700: #442508;
$RS800: #331b06;
$RS700-D: #3e2e1f;
$RS800-D: #312419;

// Gold
$GD020: #f3efd6;
$GD050: #ede6c4;
$GD100: #e3d9aa;
$GD200: #d6c684;
$GD300: #c4ab4e;
$GD400: #8e7106;
$GD500: #6e5501;
$GD600: #503d04;
$GD700: #3d2f03;
$GD800: #342803;
$GD700-D: #3d361c;
$GD800-D: #302a16;

// Olive
$OL020: #f0f0e6;
$OL050: #e8e8d6;
$OL100: #dcdbba;
$OL200: #cbc990;
$OL300: #b5b267;
$OL400: #7a7737;
$OL500: #555224;
$OL600: #413f1c;
$OL700: #363519;
$OL800: #292812;

// Forest
$FR020: #e8efec;
$FR050: #dde7e2;
$FR100: #ccdbd2;
$FR200: #a3c5b4;
$FR300: #7fae96;
$FR400: #4a7b63;
$FR500: #3b634f;
$FR600: #2d4a3c;
$FR700: #253e32;
$FR800: #1e3228;

// Plum
$PL020: #fcf4f8;
$PL050: #f6e7ee;
$PL100: #edd1df;
$PL200: #dda4c1;
$PL300: #d287ae;
$PL400: #9a3167;
$PL500: #76204f;
$PL600: #521638;
$PL700: #43122d;
$PL800: #330e23;
$PL700-D: #472235;
$PL800-D: #3a1b2b;

// Violet
$VI020: #f8f3f9;
$VI050: #f5eafa;
$VI100: #eacef7;
$VI200: #d3abe7;
$VI300: #c38cde;
$VI400: #703199;
$VI500: #4c216c;
$VI600: #321648;
$VI700: #281139;
$VI800: #1d0d2a;
$VI700-D: #382146;
$VI800-D: #2d1b39;

// Slate
$SL020: #f2f0f5;
$SL050: #e5e3eb;
$SL100: #d0c9d7;
$SL200: #b8afc0;
$SL300: #a599af;
$SL400: #766484;
$SL500: #584b63;
$SL600: #3a3242;
$SL700: #302936;
$SL800: #26212b;

// Indigo
$IN020: #f5f5fc;
$IN050: #eef;
$IN100: #dadafb;
$IN200: #b7baea;
$IN300: #999de1;
$IN400: #5d69b6;
$IN500: #434e95;
$IN600: #2a3572;
$IN700: #252e64;
$IN800: #1c254e;

// Branding
$BR-N00: #fff;
$BR-N025: #f5f6f8;
$BR-N050: #edeef1;
$BR-N075: #dadada;
$BR-N100: #c0c0c0;
$BR-N200: #686868;
$BR-N300: #282525;
$BR-N350: #1a1717;
$BR-N400: #111010;
$BR-T010: #eaf7f8;
$BR-T020: #e1fffc;
$BR-T050: #b3fef7;
$BR-T100: #67eadd;
$BR-T200: #22dbc8;
$BR-T300: #3ea2a8;
$BR-P010: #fff3f3;
$BR-P020: #ffeded;
$BR-P050: #fbdcdd;
$BR-P100: #f3c1c2;
$BR-P200: #ef9193;
$BR-P300: #e67174;
$BR-B010: #e9eeff;
$BR-B020: #ced9ff;
$BR-B050: #afc1ff;
$BR-B100: #5159f6;
$BR-B200: #351dcb;
$BR-B300: #0f0067;
/* stylelint-enable */
