// This mixin is used to force display of the scrollbar for MacOS + trackpad users (without mouse)
// The default behavior in this case would be to hide scrollbar unless the element is actively scrolled
@mixin forced-scrollbar($scrollbar-color, $width: 12px, $scrollbar-hover-color: $scrollbar-color, $height: $width) {
  /* Firefox scrollbar styling */
  scrollbar-color: $scrollbar-color transparent;
  /* stylelint-disable-next-line */
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: $height;
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: $scrollbar-color;
    border: 3px solid transparent;
    border-radius: 7px;
    /* stylelint-disable-next-line function-disallowed-list -- This color should not change with the theme */
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 2%);

    &:hover {
      background-color: $scrollbar-hover-color;
    }
  }

  &::-webkit-scrollbar-thumb:vertical {
    min-height: 25px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    min-width: 25px;
  }

  &::-webkit-scrollbar-button {
    display: none;
    height: 0;
    width: 0;
  }
}
