@use "shared/lcap/variables" as *;
@use "shared/lcap/theme" as *;
@use "shared/mixins/map-get-strict" as *;
@use "shared/theming-mixins/button";
@use "shared/theming-mixins/spinner";

@function main-theme($main-color, $text-color) {
  @return (
    "background-color": $main-color,
    "color": $text-color,
    "border-color": $main-color,
    "hover-background-color": $main-color,
    "hover-color": $text-color,
    "disabled-background-color": $main-color,
    "disabled-color": $text-color,
    "disabled-opacity": 0.5,
    "pending-background-color": $main-color
  );
}

@function themes-map($main-color, $text-color: $lcap-text-inverse) {
  @return (
    "main": main-theme($main-color, $text-color),
    "outline": map_get_strict($button-types-map, "outline"),
    "flat": map_get_strict($button-types-map, "flat")
  );
}

$button-themes-map: (
  "brand": themes-map($lcap-brand-primary, $lcap-fg-onBrand-primary),
  "positive": themes-map($lcap-success-default),
  "negative": themes-map($lcap-error-default),
  "warning": themes-map($lcap-warning-default),
  "neutral": themes-map($lcap-neutral-default),
);

@mixin theming {
  @include button.base(
    (
      font-family: $lcap-primary-font-family,
      border-radius: 4px,
      sizes: $button-sizes-map,
      themes: $button-themes-map,
    )
  ) {
    &_default {
      @include spinner.color($lcap-icon-inverse);
    }

    &_outline {
      @include spinner.color($lcap-icon-default);
    }
  }
}
