@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  --lcap-container-widget-background: #{map_get_strict($settings, "background")};
  --lcap-container-widget-border-color: #{map_get_strict($settings, "border-color")};
}
