@use "shared/mixins/overflow-ellipsis" as *;
@use "shared/mixins/select" as *;
@use "shared/theming-mixins/select";

.select {
  @include select;

  &_theme_fluid {
    &.select {
      @include select-fluid-theme(select, accent);
    }

    &.select__dropdown {
      @include select-dropdown-fluid-theme;
    }
  }

  &_theme_fluid-neutral {
    &.select {
      @include select-fluid-theme(select, neutral);
    }

    &.select__dropdown {
      @include select-dropdown-fluid-theme;
    }
  }

  &_theme_fluid-borderless {
    &.select {
      @include select-fluid-borderless;
    }

    &.select__dropdown {
      @include select-dropdown-fluid-theme;
    }
  }

  &_theme_grayscale {
    &.select {
      @include select-grayscale-theme;
    }

    &.select__dropdown {
      @include select-dropdown-grayscale-theme;
    }
  }

  &_alert-type_error {
    &.select {
      @include select-error-styles(select);
    }

    &.select__dropdown {
      @include select-dropdown-error-styles;
    }
  }

  &_alert-type_warning {
    &.select {
      @include select-warning-styles(select);
    }

    &.select__dropdown {
      @include select-dropdown-warning-styles;
    }
  }
}
