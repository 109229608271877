@use "shared/lcap/theme" as *;

/* stylelint-disable color-no-hex, function-disallowed-list */

// Neutral
$lcap-neutral-default: #637a87;
$lcap-neutral-tint: #fafbfc;
$lcap-neutral-emphasis: #476270;
$lcap-neutral-dark: #04141a;
$lcap-neutral-dark-opacity-8: rgba($lcap-neutral-dark, 0.08);
$lcap-fg-onNeutral: #fff;

// Success
$lcap-success-default: #5aa028;

// Warning
$lcap-warning-default: #e67009;
$lcap-warning-fg-emphasis: #bd5200;
$lcap-warning-tint: #faf0e3;
$lcap-warning-opacity-50: rgba($lcap-warning-default, 0.5);
$lcap-widget-warning-background: rgba(#ffbb75, 0.15);
$lcap-page-warning-background: #ffbb75;

// Error
$lcap-error-default: #da3b1c;
$lcap-error-fg-emphasis: #b6270a;
$lcap-widget-error-border: #f18470;
$lcap-widget-error-background: rgba($lcap-widget-error-border, 0.15);
$lcap-page-error-background: #f18470;
$lcap-error-tint: #fff1f0;
$lcap-error-opacity-50: rgba($lcap-error-default, 0.5);

// Accent (for further customisation)
$lcap-accent-default: $lcap-brand-primary;
$lcap-accent-opacity-10: $lcap-brand-primary-opacity-10;
$lcap-accent-opacity-50: $lcap-brand-primary-opacity-50;
$lcap-fg-onAccent: $lcap-fg-onBrand-primary;

// Plum
$lcap-fg-onPlum: #fff;
