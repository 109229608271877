.resizing {
  // stylelint-disable declaration-no-important
  user-select: none !important;

  @each $direction in (ns, ew) {
    &_#{$direction} {
      &,
      *,
      *::after,
      *::before {
        cursor: #{$direction}-resize !important;
      }
    }
  }
}
