@use "shared/mixins/media-queries" as *;

.w-page-filters {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;

  @include device(desktop-small) {
    gap: 12px;
    margin-bottom: 20px;
  }

  &__aside {
    display: flex;
    gap: 8px;

    @include device(desktop-small) {
      border-top: var(--page-filters-border);
      padding: 12px 0 0;
      width: 100%;
    }

    &_hide-on-mobile {
      @include device(phone) {
        display: none;
      }
    }
  }

  &__main {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 8px;

    @include device(desktop-small) {
      width: 100%;
    }
  }

  &__item {
    &:empty {
      display: none;
    }

    @include device(desktop-small) {
      display: flex;
    }

    &_search {
      flex: 0 1 288px;

      @include device(desktop-small) {
        display: block;
        flex: 0;
      }
    }

    &_separator {
      border-right: var(--page-filters-border);
      padding-right: 8px;

      @include device(desktop-small) {
        border-bottom: var(--page-filters-border);
        border-right: none;
        margin-bottom: 4px;
        padding: 0 0 12px;
        width: 100%;
      }
    }
  }
}
