/* stylelint-disable color-no-hex, function-disallowed-list */

$lcap-default-shadow:
  0 3px 3px rgb(4 20 26 / 3%),
  0 8px 12px -14px rgb(4 20 26 / 8%);
$lcap-footer-shadow:
  0 -3px 3px rgb(4 20 26 / 3%),
  0 -8px 12px -14px rgb(4 20 26 / 8%);
$lcap-dropdown-shadow:
  0 12px 12px rgb(4 20 26 / 3%),
  0 4px 12px -2px rgb(4 20 26 / 8%);
