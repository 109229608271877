@use "shared/lcap/variables/colors" as *;

// TODO: move to LcapThemeService
:root {
  --lcap-blue-subtle: #{$B100};
  --lcap-violet-subtle: #{$VI100};
  --lcap-green-subtle: #{$G100};
  --lcap-red-subtle: #{$R100};
  --lcap-orange-subtle: #{$O100};
  --lcap-gold-subtle: #{$GD100};
  --lcap-indigo-subtle: #{$IN100};
  --lcap-brown-subtle: #{$BR100};
  --lcap-teal-subtle: #{$T100};
  --lcap-plum-subtle: #{$PL100};
  --lcap-slate-subtle: #{$SL050};
  --lcap-grey-subtle: #{$N075};
}
