@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    --collapsible-background: #{map_get_strict($settings, "background")};

    @include _faded-out(map_get_strict($settings, "faded-out"));
    @include _border-vars(map_get_strict($settings, "border"));
    @include _button-vars(map_get_strict($settings, "button"));
  }
}

@mixin _faded-out($settings) {
  --collapsible-faded-out-height: #{map_get_strict($settings, "height")};
  --collapsible-faded-out-background-image: #{map_get_strict($settings, "background-image")};
}

@mixin _border-vars($settings) {
  --collapsible-border-margin-bottom: #{map_get_strict($settings, "margin-bottom")};
  --collapsible-border-font-size: #{map_get_strict($settings, "font-size")};
  --collapsible-border-height: #{map_get_strict($settings, "height")};
  --collapsible-border-padding: #{map_get_strict($settings, "padding")};
}

@mixin _button-vars($settings) {
  --collapsible-button-color: #{map_get_strict($settings, "color")};
  --collapsible-button-hover-color: #{map_get_strict($settings, "hover-color")};
}
