// Borrowed from select2
/* stylelint-disable declaration-no-important */
@mixin invisible {
  border: 0 none !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  left: 0 !important;
  margin: 0 !important;
  outline: 0 none !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 1px !important;
}
/* stylelint-enable */
