.label {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 3px 0 8px;

  &_compact {
    padding-bottom: var(--label-compact-padding-bottom, 3px);
  }

  &_complex {
    padding-top: 0;
  }

  &_flex {
    display: flex;
  }
}
