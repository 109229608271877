/* stylelint-disable color-no-hex, function-disallowed-list */

$lcap-border-default: #ced5db;
$lcap-border-emphasis: #637a87;
$lcap-border-subtle: #e4e8eb;
$lcap-neutral-border: #bac4cc;
$lcap-neutral-border-emphasis: #476270;

$lcap-border-radius-xs: 2px;
$lcap-border-radius-s: 4px;
$lcap-border-radius-m: 8px;
$lcap-border-radius-l: 12px;
