/* stylelint-disable color-no-hex, function-disallowed-list */

@use "shared/lcap/variables/backgrounds" as *;

$lcap-scrollbar-color: rgb(186 196 204 / 40%); // $N100 @40%
$lcap-scrollbar-hover-color: rgb(186 196 204 / 80%); // $N100 @80%

$lcap-elevation-1:
  0 3px 3px rgba($lcap-surface-dark, 0.03),
  0 8px 12px -14px rgba($lcap-surface-dark, 0.03);
$lcap-elevation-8:
  0 12px 12px rgba($lcap-surface-dark, 0.03),
  0 4px 12px -2px rgba($lcap-surface-dark, 0.08);
