@use "shared/mixins/form-control" as *;

.plain-text-field {
  @include form-control;

  display: flex;
  height: auto;
  min-height: var(--form-control-plain-text-field-min-height);
  padding: 5px 15px;
  position: relative;
  transition:
    box-shadow ease-in-out 250ms,
    border-color ease-in-out 250ms;

  &#{&}_textarea {
    min-height: 100px;
    resize: vertical;
  }

  &#{&}_compact {
    border-radius: var(--form-control-plain-text-field-compact-border-radius);
    min-height: 36px;
    padding: 4px 12px;
  }

  &#{&}_error {
    border-color: var(--form-control-plain-text-field-error-border-color);

    &:focus {
      border-color: var(--form-control-plain-text-field-error-focus-border-color);
    }
  }
}
