@use "shared/mixins/button-pseudo-link" as *;
@use "shared/theming-mixins/link";

.link {
  @at-root {
    button#{&} {
      @include button-pseudo-link($color: false, $hover-color: false);
    }
  }

  color: var(--link-color);
  text-decoration: underline;

  &:focus,
  &:hover {
    color: var(--link-hover-color);
  }

  w-svg-icon {
    margin-left: 4px;
  }

  &_underline {
    text-decoration: underline;
  }

  &_no-underline {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:disabled {
    color: inherit;
    cursor: default;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &_active {
    color: var(--link-active-color);

    &:hover {
      color: var(--link-active-hover-color);
    }
  }

  &_brand {
    color: var(--link-brand-color);

    &:focus,
    &:hover {
      color: var(--link-brand-hover-color);
    }
  }

  &_neutral {
    color: var(--link-neutral-color);

    &:focus,
    &:hover {
      color: var(--link-neutral-hover-color);
    }
  }

  &_secondary {
    color: var(--link-secondary-color);

    &:focus,
    &:hover {
      color: var(--link-secondary-hover-color);
    }
  }

  &_help {
    text-decoration: none;
  }

  &_inherit {
    color: inherit;

    &:focus,
    &:hover {
      color: inherit;
    }
  }

  &_unset {
    color: unset;

    &:focus,
    &:hover {
      color: unset;
    }
  }

  &_pending {
    cursor: not-allowed;
    pointer-events: none;
  }

  &_download {
    color: var(--link-download-color);
    padding-bottom: 2px;
    position: relative;
    text-decoration: none;

    &::before {
      background: var(--link-download-underline-color);
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &::after {
      background: var(--link-download-underline-hover-color);
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      right: 100%;
      transition: right 0.15s ease-in-out;
    }

    &:hover {
      &::after {
        opacity: 1;
        right: 0;
      }
    }

    &.link_pending {
      &::after {
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-name: pending-animation;
      }
    }
  }

  @keyframes pending-animation {
    0% {
      left: 0;
      right: 100%;
    }

    50% {
      left: 0;
      right: 0;
    }

    100% {
      left: 100%;
      right: 0;
    }
  }
}
