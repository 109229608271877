@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include base-vars($settings);
  }
}

@mixin base-vars($settings) {
  --multi-input-active-border: #{map_get_strict($settings, "active-border")};
  --multi-input-inner-border: #{map_get_strict($settings, "inner-border")};
  --multi-input-active-shadow: #{map_get_strict($settings, "active-shadow")};
  --multi-input-warning-border: #{map_get_strict($settings, "warning-border")};
  --multi-input-warning-active-border: #{map_get_strict($settings, "warning-active-border")};
  --multi-input-error-border: #{map_get_strict($settings, "error-border")};
  --multi-input-error-active-border: #{map_get_strict($settings, "error-active-border")};
  --multi-input-disabled-border: #{map_get_strict($settings, "disabled-border")};
  --multi-input-disabled-background: #{map_get_strict($settings, "disabled-border")};
  --multi-input-disabled-color: #{map_get_strict($settings, "disabled-color")};
  --multi-input-disabled-type-background: #{map_get_strict($settings, "disabled-type-background")};
  --multi-input-disabled-type-color: #{map_get_strict($settings, "disabled-type-color")};
  --multi-input-placeholder-color: #{map_get_strict($settings, "placeholder-color")};
}
