@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  @include _add-button-vars(map_get_strict($settings, "add-button"));
  @include _dropdown-vars(map_get_strict($settings, "dropdown"));
}

@mixin _add-button-vars($settings) {
  --extensible-filter-list-add-button-background: #{map_get($settings, "background")};
  --extensible-filter-list-add-button-border: #{map_get($settings, "border")};
  --extensible-filter-list-add-button-border-radius: #{map_get($settings, "border-radius")};
  --extensible-filter-list-add-button-color: #{map_get($settings, "color")};
  --extensible-filter-list-add-button-hover-background: #{map_get($settings, "hover-background")};
  --extensible-filter-list-add-button-hover-border-color: #{map_get($settings, "hover-border-color")};
  --extensible-filter-list-add-button-hover-color: #{map_get($settings, "hover-color")};
  --extensible-filter-list-add-button-active-background: #{map_get($settings, "active-background")};
  --extensible-filter-list-add-button-active-border-color: #{map_get($settings, "active-border-color")};
  --extensible-filter-list-add-button-active-color: #{map_get($settings, "active-color")};
  --extensible-filter-list-add-button-disabled-background: #{map_get($settings, "disabled-background")};
  --extensible-filter-list-add-button-disabled-border-color: #{map_get($settings, "disabled-border-color")};
  --extensible-filter-list-add-button-disabled-color: #{map_get($settings, "disabled-color")};
}

@mixin _dropdown-vars($settings) {
  --extensible-filter-list-dropdown-color: #{map_get($settings, "color")};
}
