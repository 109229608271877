@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  @include _action-vars(map_get_strict($settings, "action"));
}

@mixin _action-vars($settings) {
  --filter-dropdown-actions-action-border-radius: #{map_get($settings, "border-radius")};
  --filter-dropdown-actions-action-color: #{map_get($settings, "color")};
  --filter-dropdown-actions-action-transition: #{map_get($settings, "transition")};
  --filter-dropdown-actions-action-save-color: #{map_get($settings, "save-color")};
  --filter-dropdown-actions-action-save-hover-background: #{map_get($settings, "save-hover-background")};
  --filter-dropdown-actions-action-save-hover-color: #{map_get($settings, "save-hover-color")};
  --filter-dropdown-actions-action-cancel-hover-background: #{map_get($settings, "cancel-hover-background")};
  --filter-dropdown-actions-action-cancel-hover-color: #{map_get($settings, "cancel-hover-color")};
}
