@use "shared/mixins/map-get-strict" as *;
@use "shared/mixins/wrap-with-root" as *;

@mixin base($settings) {
  @include wrap-with-root {
    @include _base-vars($settings);
  }
}

@mixin _base-vars($settings) {
  @include _dropdown-vars(map_get_strict($settings, "dropdown"));
  @include _dropdown-input-vars(map_get_strict($settings, "dropdown-input"));
}

@mixin _dropdown-vars($settings) {
  --conditional-filter-dropdown-background: #{map_get($settings, "background")};
}

@mixin _dropdown-input-vars($settings) {
  --conditional-filter-dropdown-input-background: #{map_get($settings, "background")};
  --conditional-filter-dropdown-input-border: #{map_get($settings, "border")};
  --conditional-filter-dropdown-input-border-radius: #{map_get($settings, "border-radius")};
  --conditional-filter-dropdown-input-placeholder-color: #{map_get($settings, "placeholder-color")};
  --conditional-filter-dropdown-input-transition: #{map_get($settings, "transition")};
  --conditional-filter-dropdown-input-focus-border-color: #{map_get($settings, "focus-border-color")};
  --conditional-filter-dropdown-input-focus-box-shadow: #{map_get($settings, "focus-box-shadow")};
  --conditional-filter-dropdown-input-disabled-background: #{map_get($settings, "disabled-background")};
}
